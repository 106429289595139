import { Form } from 'antd'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getTerminalsWithOptions } from '../../../store/common/entity/terminal/terminal.selectors'
import { FranchisesFormValues } from '../../../components/franchises/franchises-form/franchises-form.types'
import { getTillsWithOptions } from '../../../store/common/entity/till/till.selectors'
import {
  getCustomersOptions,
  getFranchiseFormValues,
  getFranchisesIsLoading,
  getFranchisesIsUpdating,
  getStudiosFranchisesOptions,
} from '../../../store/pages/franchises-edit-page/franchises-edit-page.selectors'
import { franchisesEditPageActions } from '../../../store/pages/franchises-edit-page/franchises-edit-page.slice'
import { isDef } from '../../../types/lang.types'
import { useFranchisesEditPageParams } from '../franchises-edit-page-hooks/franchises-edit-page-params.hook'

export function useFranchisesEditPageForm() {
  const [form] = Form.useForm<FranchisesFormValues>()

  const { id } = useFranchisesEditPageParams()

  const dispatch = useDispatch()
  const terminals = useSelector(getTerminalsWithOptions)
  const tills = useSelector(getTillsWithOptions)

  const franchiseFormValues = useSelector(getFranchiseFormValues)
  const customersOptions = useSelector(getCustomersOptions)

  const isLoading = useSelector(getFranchisesIsLoading)
  const isUpdating = useSelector(getFranchisesIsUpdating)
  const studiosOptions = useSelector(getStudiosFranchisesOptions)

  React.useEffect(() => {
    if (isDef(franchiseFormValues)) {
      form.setFieldsValue(franchiseFormValues)
    }
  }, [form, franchiseFormValues])

  const onFinishHandler = React.useCallback(
    (values: FranchisesFormValues): void => {
      if (isDef(id)) {
        dispatch(franchisesEditPageActions.updateFranchise({ id, data: values }))
      }
    },
    [dispatch, id]
  )

  return {
    form,
    customersOptions,
    onFinishHandler,
    terminals,
    tills,
    isLoading,
    isUpdating,
    studiosOptions,
  }
}
