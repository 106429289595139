import { Alert, Button, Flex, Form, FormInstance, Input, Typography } from 'antd'
import { FC, ReactNode } from 'react'

import { ClientsApi } from '../../../api/types/clients-api.types'
import { ClientsAutocompleteContainer } from '../../../containers/clients-autocomplete/clients-autocomplete.container'
import { Nullable } from '../../../types/lang.types'
import { CustomCard } from '../../custom-card/custom-card.component'
import { useClientsDataFields } from './clients-data-fields.hook'

interface ClientsDataFieldsProps {
  phoneFieldLabel: ReactNode
  form: FormInstance<any>
  isLoading: boolean
  setIsLoading: (isLoading: boolean) => void
  setClientData: (value: React.SetStateAction<Nullable<ClientsApi.Client>>) => void
}

export const ClientsDataFields: FC<ClientsDataFieldsProps> = props => {
  const { phoneFieldLabel, form, isLoading } = props
  const { setIsLoading, setClientData } = props

  const {
    phoneValidationRules,
    isAdditionalFieldsShown,
    isIncompleteMessageShown,
    userMissingFields,
    onSetClientId,
    onAddNewClient,
    onValidateClientPhone,
    onFillIncompleteClientData,
    onHideAdditionalFields,
  } = useClientsDataFields({
    form,
    setIsLoading,
    setClientData,
  })

  return (
    <>
      <Form.Item label={phoneFieldLabel} name="phone" rules={phoneValidationRules} validateTrigger="onBlur">
        <ClientsAutocompleteContainer
          name="phone"
          form={form}
          disabled={isLoading}
          setClientId={onSetClientId}
          onAddClient={onAddNewClient}
          onBlur={onValidateClientPhone}
        />
      </Form.Item>
      <Form.Item style={{ display: 'none' }} name="clientId" />
      {isIncompleteMessageShown && (
        <Alert
          type="info"
          showIcon
          message={
            <Flex vertical gap={4} align="baseline">
              <Typography.Text>
                У клиента не заполнено{' '}
                {userMissingFields
                  .map(field => {
                    if (field === 'firstName') return 'имя'
                    if (field === 'lastName') return 'фамилия'
                    if (field === 'email') return 'email'
                    return null
                  })
                  .join(', ')}{' '}
                — хотите заполнить?
              </Typography.Text>
              <Button size="small" onClick={onFillIncompleteClientData}>
                Заполнить
              </Button>
            </Flex>
          }
        />
      )}
      {isAdditionalFieldsShown && (
        <CustomCard customClassName="exercises-form__custom-card">
          <Flex vertical gap="middle">
            {userMissingFields.includes('firstName') && (
              <Form.Item label="Имя клиента" name="firstName">
                <Input placeholder="Имя" disabled={isLoading} />
              </Form.Item>
            )}
            {userMissingFields.includes('lastName') && (
              <Form.Item label="Фамилия клиента" name="lastName">
                <Input placeholder="Фамилия" disabled={isLoading} />
              </Form.Item>
            )}
            {userMissingFields.includes('email') && (
              <Form.Item
                label="E-mail"
                name="email"
                rules={[
                  {
                    type: 'email',
                    message: 'Поле должно содержать email',
                  },
                ]}
              >
                <Input placeholder="E-mail" disabled={isLoading} />
              </Form.Item>
            )}
            <Button type="link" onClick={onHideAdditionalFields}>
              Скрыть
            </Button>
          </Flex>
        </CustomCard>
      )}
    </>
  )
}
