import { NNumber, NString, Nullable } from '../../types/lang.types'
import { ColoredDictionaryItem, PaginationParamsDTO } from './api.types'
import { CustomFieldsApi } from './custom-fields-api.types'

export namespace ClientsApi {
  export enum ClientSex {
    U = 'U',
    F = 'F',
    M = 'M',
  }

  export interface Client {
    id: string
    email: string
    firstName: string
    lastName: string
    middleName: NString
    sex: ClientSex
    photo: NString
    phone: string
    birthDate: NString
    withCard: boolean
    registrationDate: string
    lastLogin: string
    lastVisit: string
    deposit: number
    source: string
    loyaltyCard: string
    comment: NString
    clientCategory: Nullable<ColoredDictionaryItem<number>>
    trialUsed: boolean
    category: ClientCategory
    customFields: Nullable<CustomFieldsApi.CustomField[]>
    managerId: NString
  }

  export interface ClientCategory {
    id: number
    name: string
  }
  export interface ClientDTO {
    email?: string
    firstName?: string
    lastName?: string
    middleName?: NString
    sex?: ClientSex
    photo?: NString
    phone: string
    birthDate?: NString
    loyaltyCard?: string
    deposit?: number
    source?: string
    comment?: NString
    clientCategoryId?: NNumber
    trialUsed?: boolean
  }

  export interface ClientsFetchAllParams extends PaginationParamsDTO {
    phone: NString
  }
}
