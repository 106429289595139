import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

import { Nullable } from '../../../types/lang.types'
import { Pagination } from '../../../api/types/api.types'
import { MasterServicesApi } from '../../../api/types/master-services-api.types'
import { ServiceCategoriesApi } from '../../../api/types/service-categories-api.types'
import { StudiosApi } from '../../../api/types/studios-api.types'
import { TrainersApi } from '../../../api/types/trainers-api.types'
import {
  MasterServicesEditPageCreateSubServicePayload,
  MasterServicesEditPageSubserviceDisconnectionPayload,
  MasterServicesEditPageRemoveSubServicePayload,
  MasterServicesEditPageUpdatePayload,
  MasterServicesEditPageUpdateSubServicePayload,
  MasterServicesEditPageUpdatePricingPayload,
  MasterServicesEditPageRemovePricingPayload,
} from './master-services-edit-page.types'
import { ExercisesTypesApi } from '../../../api/types/exercises-types-api.types'
import { PricingApi } from '../../../api/types/pricing.types'
import { MasterServicesPricingFormValues } from '../../../components/master-services/master-services-form/master-services-form.types'
import { EmployeesPositionsApi } from '../../../api/types/employees-positions-api.types'

export interface MasterServicesEditPageState {
  masterService: Nullable<MasterServicesApi.MasterService>
  exercisesTypes: Nullable<Pagination<ExercisesTypesApi.ExerciseType>>
  trainers: Nullable<Pagination<TrainersApi.Trainer>>
  studios: Nullable<Pagination<StudiosApi.Studio>>
  serviceCategories: Nullable<ServiceCategoriesApi.ServiceCategory[]>
  pricing: Nullable<Pagination<PricingApi.Pricing>>
  positions: Nullable<Pagination<EmployeesPositionsApi.EmployeePosition>>
  selectedPricing: Nullable<PricingApi.Pricing>
  isLoading: boolean
  isLoaded: boolean
  error: Nullable<Error>
}

const initialState: MasterServicesEditPageState = {
  masterService: null,
  exercisesTypes: null,
  trainers: null,
  studios: null,
  serviceCategories: null,
  pricing: null,
  positions: null,
  selectedPricing: null,
  isLoading: false,
  isLoaded: false,
  error: null,
}

export const { actions: masterServicesEditPageActions, reducer: masterServicesEditPageReducer } = createSlice({
  name: '@@master-services-edit-page',
  initialState,
  reducers: {
    fetchPageData: (state: Draft<MasterServicesEditPageState>, _: PayloadAction<string>) => {
      state.isLoading = true
    },
    fetchPageDataSuccess: (state: Draft<MasterServicesEditPageState>) => {
      state.isLoading = false
      state.isLoaded = true
    },
    fetchPageDataError: (state: Draft<MasterServicesEditPageState>, action: PayloadAction<Error>) => {
      state.isLoading = false
      state.error = action.payload
    },
    fetchMasterService: (state: Draft<MasterServicesEditPageState>, _: PayloadAction<string>) => {
      state.isLoading = true
    },
    fetchMasterServiceSuccess: (
      state: Draft<MasterServicesEditPageState>,
      action: PayloadAction<MasterServicesApi.MasterService>
    ) => {
      state.masterService = action.payload
      state.isLoading = false
      state.isLoaded = true
    },
    fetchMasterServiceError: (state: Draft<MasterServicesEditPageState>, action: PayloadAction<Error>) => {
      state.isLoading = false
      state.error = action.payload
    },
    fetchExercisesTypes: (state: Draft<MasterServicesEditPageState>) => {
      state.isLoading = true
    },
    fetchExercisesTypesSuccess: (
      state: Draft<MasterServicesEditPageState>,
      action: PayloadAction<Nullable<Pagination<ExercisesTypesApi.ExerciseType>>>
    ) => {
      state.exercisesTypes = action.payload
      state.isLoading = false
      state.isLoaded = true
    },
    fetchExercisesTypesError: (state: Draft<MasterServicesEditPageState>, action: PayloadAction<Error>) => {
      state.error = action.payload
      state.isLoading = false
      state.isLoaded = true
    },
    fetchTrainers: (state: Draft<MasterServicesEditPageState>) => {
      state.isLoading = true
    },
    fetchTrainersSuccess: (
      state: Draft<MasterServicesEditPageState>,
      action: PayloadAction<Nullable<Pagination<TrainersApi.Trainer>>>
    ) => {
      state.trainers = action.payload
      state.isLoading = false
      state.isLoaded = true
    },
    fetchTrainersError: (state: Draft<MasterServicesEditPageState>, action: PayloadAction<Error>) => {
      state.error = action.payload
      state.isLoading = false
      state.isLoaded = true
    },
    fetchStudios: (state: Draft<MasterServicesEditPageState>) => {
      state.isLoading = true
    },
    fetchStudiosSuccess: (
      state: Draft<MasterServicesEditPageState>,
      action: PayloadAction<Nullable<Pagination<StudiosApi.Studio>>>
    ) => {
      state.studios = action.payload
      state.isLoading = false
      state.isLoaded = true
    },
    fetchStudiosError: (state: Draft<MasterServicesEditPageState>, action: PayloadAction<Error>) => {
      state.error = action.payload
      state.isLoading = false
      state.isLoaded = true
    },
    fetchPricing: (state: Draft<MasterServicesEditPageState>) => {
      state.isLoading = true
    },
    fetchPricingSuccess: (
      state: Draft<MasterServicesEditPageState>,
      action: PayloadAction<Nullable<Pagination<PricingApi.Pricing>>>
    ) => {
      state.pricing = action.payload
      state.isLoading = false
      state.isLoaded = true
    },
    fetchPricingError: (state: Draft<MasterServicesEditPageState>, action: PayloadAction<Error>) => {
      state.error = action.payload
      state.isLoading = false
      state.isLoaded = true
    },
    fetchPricingById: (state: Draft<MasterServicesEditPageState>, action: PayloadAction<string>) => {
      state.isLoading = true
    },
    fetchPricingByIdSuccess: (
      state: Draft<MasterServicesEditPageState>,
      action: PayloadAction<Nullable<PricingApi.Pricing>>
    ) => {
      state.selectedPricing = action.payload
      state.isLoading = false
      state.isLoaded = true
    },
    fetchPricingByIdError: (state: Draft<MasterServicesEditPageState>, action: PayloadAction<Error>) => {
      state.error = action.payload
      state.isLoading = false
      state.isLoaded = true
    },
    fetchPositions: (state: Draft<MasterServicesEditPageState>) => {
      state.isLoading = true
    },
    fetchPositionsSuccess: (
      state: Draft<MasterServicesEditPageState>,
      action: PayloadAction<Nullable<Pagination<EmployeesPositionsApi.EmployeePosition>>>
    ) => {
      state.positions = action.payload
      state.isLoading = false
      state.isLoaded = true
    },
    fetchPositionsError: (state: Draft<MasterServicesEditPageState>, action: PayloadAction<Error>) => {
      state.error = action.payload
      state.isLoading = false
      state.isLoaded = true
    },
    fetchServiceCategories: (state: Draft<MasterServicesEditPageState>) => {
      state.isLoading = true
    },
    fetchServiceCategoriesSuccess: (
      state: Draft<MasterServicesEditPageState>,
      action: PayloadAction<Nullable<ServiceCategoriesApi.ServiceCategory[]>>
    ) => {
      state.serviceCategories = action.payload
      state.isLoading = false
      state.isLoaded = true
    },
    fetchServiceCategoriesError: (state: Draft<MasterServicesEditPageState>, action: PayloadAction<Error>) => {
      state.error = action.payload
      state.isLoading = false
      state.isLoaded = true
    },
    updateMasterService: (
      state: Draft<MasterServicesEditPageState>,
      _: PayloadAction<MasterServicesEditPageUpdatePayload>
    ) => {
      state.isLoading = true
    },
    updateMasterServiceSuccess: (state: Draft<MasterServicesEditPageState>) => {
      state.isLoading = false
    },
    updateMasterServiceError: (state: Draft<MasterServicesEditPageState>, action: PayloadAction<Error>) => {
      state.error = action.payload
    },
    createSubService: (
      state: Draft<MasterServicesEditPageState>,
      _: PayloadAction<MasterServicesEditPageCreateSubServicePayload>
    ) => {
      state.isLoading = true
    },
    createSubServiceSuccess: (state: Draft<MasterServicesEditPageState>) => {
      state.isLoading = false
    },
    createSubServiceError: (state: Draft<MasterServicesEditPageState>, action: PayloadAction<Error>) => {
      state.error = action.payload
    },
    createPricing: (state: Draft<MasterServicesEditPageState>, _: PayloadAction<MasterServicesPricingFormValues>) => {
      state.isLoading = true
    },
    createPricingSuccess: (state: Draft<MasterServicesEditPageState>) => {
      state.isLoading = false
    },
    createPricingError: (state: Draft<MasterServicesEditPageState>, action: PayloadAction<Error>) => {
      state.error = action.payload
      state.isLoading = false
    },
    updatePricing: (
      state: Draft<MasterServicesEditPageState>,
      _: PayloadAction<MasterServicesEditPageUpdatePricingPayload>
    ) => {
      state.isLoading = true
    },
    updatePricingSuccess: (state: Draft<MasterServicesEditPageState>) => {
      state.isLoading = false
    },
    updatePricingError: (state: Draft<MasterServicesEditPageState>, action: PayloadAction<Error>) => {
      state.error = action.payload
      state.isLoading = false
    },
    removePricing: (
      state: Draft<MasterServicesEditPageState>,
      _: PayloadAction<MasterServicesEditPageRemovePricingPayload>
    ) => {
      state.isLoading = true
    },
    removePricingSuccess: (state: Draft<MasterServicesEditPageState>) => {
      state.isLoading = false
    },
    removePricingError: (state: Draft<MasterServicesEditPageState>, action: PayloadAction<Error>) => {
      state.error = action.payload
      state.isLoading = false
    },
    updateSubService: (
      state: Draft<MasterServicesEditPageState>,
      _: PayloadAction<MasterServicesEditPageUpdateSubServicePayload>
    ) => {
      state.isLoading = true
    },
    updateSubServiceSuccess: (state: Draft<MasterServicesEditPageState>) => {
      state.isLoading = false
    },
    updateSubServiceError: (state: Draft<MasterServicesEditPageState>, action: PayloadAction<Error>) => {
      state.error = action.payload
    },
    removeSubService: (
      state: Draft<MasterServicesEditPageState>,
      _: PayloadAction<MasterServicesEditPageRemoveSubServicePayload>
    ) => {
      state.isLoading = true
    },
    removeSubServiceSuccess: (state: Draft<MasterServicesEditPageState>) => {
      state.isLoading = false
    },
    removeSubServiceError: (state: Draft<MasterServicesEditPageState>, action: PayloadAction<Error>) => {
      state.error = action.payload
    },
    subserviceDisconnection: (
      state: Draft<MasterServicesEditPageState>,
      _: PayloadAction<MasterServicesEditPageSubserviceDisconnectionPayload>
    ) => {
      state.isLoading = true
    },
    subserviceDisconnectionSuccess: (state: Draft<MasterServicesEditPageState>) => {
      state.isLoading = false
    },
    subserviceDisconnectionError: (state: Draft<MasterServicesEditPageState>, action: PayloadAction<Error>) => {
      state.error = action.payload
    },
    reset: () => initialState,
  },
})
