import { ContractsApi } from '../api/types/contracts-api.types'
import { formatRubleCurrency } from './number.format'

export const formatContractBalance = (
  contractAmount: number,
  contractType: ContractsApi.ContractsType,
  contractTurnover: number,
  contractStatus: ContractsApi.ContractsStatus
) => {
  if (!['ACTIVE', 'AWAITS_PAYMENT'].includes(contractStatus)) {
    return {
      balance: formatRubleCurrency(0),
    }
  }

  const adjustedBalance =
    contractType === 'POSTPAY' ? (contractTurnover !== 0 ? -contractTurnover : 0) : contractAmount - contractTurnover
  const formattedBalance = formatRubleCurrency(adjustedBalance)

  return {
    balance: formattedBalance,
  }
}
