import { createSelector } from '@reduxjs/toolkit'

import {
  genCounterpartiesFormValues,
  genCounterpartyByIdDebitAndCredit,
  genCounterpartyTotalTurnover,
} from '../../../../mapping/contracts-info-mapping'
import { ContractsApi } from '../../../../api/types/contracts-api.types'
import { Nullable } from '../../../../types/lang.types'
import { AppState } from '../../../app.store'

export const getCounterpartyByIdInternal = (state: AppState): Nullable<ContractsApi.Counterparties> =>
  state.contractsInfoPage.contractsInfoPageDescription.counterparty

export const getCounterpartyByIdContractsInternal = (state: AppState): Nullable<ContractsApi.Contracts[]> =>
  state.contractsInfoPage.contractsInfoPageDescription.counterparty?.activeContracts

export const getCounterpartyByIdIsLoading = (state: AppState): boolean =>
  state.contractsInfoPage.contractsInfoPageDescription.isLoading

export const getCounterpartyFormValue = createSelector(getCounterpartyByIdInternal, genCounterpartiesFormValues)

export const getCounterpartyIsDeleting = (state: AppState): boolean =>
  state.contractsInfoPage.contractsInfoPageDescription.isDeleting

export const getCounterpartyTotalTurnover = createSelector(getCounterpartyByIdInternal, genCounterpartyTotalTurnover)

export const getCounterpartyByIdDebitAndCredit = createSelector(getCounterpartyByIdInternal, counterparty =>
  genCounterpartyByIdDebitAndCredit(counterparty)
)
