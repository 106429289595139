import { FC } from 'react'
import { Modal } from 'antd'

import { ExercisesForm } from '../exercises-form/exercises-form.component'
import { IExercisesModalProps } from './exercises-modal.types'

export const ExercisesModal: FC<IExercisesModalProps> = ({
  title,
  form,
  studioOffset,
  loading,
  exercisesTypes,
  trainers,
  directionIsDisabled,
  exercisesTypeIsDisabled,
  onCancel,
  onSave,
  onChangeTime,
  saveStatus,
  currentRoom,
  roomsOptions,
  isFrozenTime,
  schedule,
}) => {
  return (
    <Modal title={title} onCancel={onCancel} open={true} footer={null}>
      <ExercisesForm
        loading={loading}
        form={form}
        exercisesTypes={exercisesTypes}
        trainers={trainers}
        currentRoom={currentRoom}
        roomsOptions={roomsOptions}
        directionIsDisabled={directionIsDisabled}
        exercisesTypeIsDisabled={exercisesTypeIsDisabled}
        studioOffset={studioOffset}
        onSave={onSave}
        onCancel={onCancel}
        onChangeTime={onChangeTime}
        saveStatus={saveStatus}
        isFrozenTime={isFrozenTime}
        schedule={schedule}
      />
    </Modal>
  )
}
