import { useDispatch, useSelector } from 'react-redux'
import React from 'react'
import { useHistory } from 'react-router-dom'

import { useCounterpartiesPageParams } from './contracts-page-hooks/contracts-page.hook'
import { genListPaginationConfig } from '../../utils/pagination.utils'
import { genContractsPagePath } from '../../format/path.format'
import {
  getCounterpartiesIsLoading,
  getCounterpartiesTotalElements,
} from '../../store/pages/contracts-page/contracts-page.selectors'
import { contractsPageActions } from '../../store/pages/contracts-page/contracts-page.slice'

export function useContractsPage() {
  const { push } = useHistory()

  const dispatch = useDispatch()
  const isLoading = useSelector(getCounterpartiesIsLoading)
  const totalElements = useSelector(getCounterpartiesTotalElements)

  const { page, size } = useCounterpartiesPageParams()

  const pagination = React.useMemo(
    () => genListPaginationConfig(page, size, totalElements),
    [page, size, totalElements]
  )

  const onChangePageHandler = React.useCallback(
    (page: number, pageSize: number): void => {
      push(genContractsPagePath({ page: page, size: pageSize }))
    },
    [push]
  )

  const onChangePageSizeHandler = React.useCallback(
    (pageSize: number): void => {
      push(genContractsPagePath({ page, size: pageSize }))
    },
    [page, push]
  )

  React.useEffect(() => {
    dispatch(contractsPageActions.fetchAllCounterparties({ page, size }))
    dispatch(contractsPageActions.fetchAllManagers())
  }, [dispatch, page, size])

  React.useEffect(() => {
    return () => {
      dispatch(contractsPageActions.reset())
    }
  }, [dispatch])

  return {
    isLoading,
    pagination,
    onChangePageHandler,
    onChangePageSizeHandler,
  }
}
