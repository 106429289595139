import parsePhoneNumberFromString, { AsYouType, formatIncompletePhoneNumber, parsePhoneNumber } from 'libphonenumber-js'

import { NString, isDef } from '../types/lang.types'

export const normalizePhone = (phone: string) => {
  const parsedPhone = parsePhoneNumberFromString(phone, 'RU')
  return parsedPhone ? parsedPhone.format('E.164') : ''
}

export const formatPhoneNumber = (phone: string) => {
  return formatIncompletePhoneNumber(phone, { defaultCountry: 'RU' })
}

export const formatFormValueToPhoneNumber = (value: string) => {
  return parsePhoneNumber(value, 'RU')?.number || ''
}

export const formatSearchPhoneNumber = (value: NString) => {
  if (!isDef(value)) return
  if (!value.match(/^\d/) && !value.match(/\d+/)) return value
  if (value.length === 6 && value.substring(0, 1) === '8') {
    return new AsYouType().input(value)
  }
  return formatPhoneNumber(value)
}
