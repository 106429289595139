import { isBoolean, isDef, parseBoolean } from '../../../types/lang.types'

export function genServicesInitialValues() {
  return {
    hasStudioLimitation: false,
    hasDirectionLimitation: false,
    hasTypeLimitation: false,
    name: '',
    description: '',
    format: 'BOOKING_BIND',
    cost: 0,
    trialCost: 0,
  }
}

export const validateProductsServicesNumberInput = (_: any, value: string) => {
  if (!isDef(value) || value === '') {
    return Promise.reject(new Error('Введите значение'))
  }
  if (Number(value) >= 0) {
    return Promise.resolve()
  }
  return Promise.reject(new Error('Значение должно быть больше или равно 0'))
}
export const validateProductsServicesNoSpacesOnly = (_: any, value: string) => {
  if (value && value.trim() !== '') {
    return Promise.resolve()
  }
  return Promise.reject(new Error('Введите название услуги'))
}
export function castStringToBoolean(value: boolean | string): boolean {
  if (isBoolean(value)) {
    return Boolean(value)
  }
  return parseBoolean(value)
}
export const genProductServicesQuestions = {
  1: 'Используйте этот режим для услуг типа "оплата парковки", "заморозка абонемента", "аренда оборудования", "дополнительное время тренировки", "доставка документов", "экспресс-услуги по уборке", "доступ к видеокурсу" и других услуг, которые оказываются в моменте.',
  2: 'Используйте этот режим, если услуга создается для того, чтобы клиент мог оплатить свою запись. Например, запись на маникюр, аренда спортивного корта, оплата онлайн посещения.',
  3: 'Услуга для оплаты исполнителя — используйте этот режим, если услуга создается для оплаты исполнителя как дополнительная опция к записи клиента. Например, вы продаёте аренду корта, а тренера предлагаете как дополнительную услугу.',
  4: 'Клиент, который будет впервые приобретать эту услугу, сможет оформить её за эту цену',
  5: 'Включите это свойство, если хотите ограничить количество продаваемых услуг. Есть два дополнительных режима: можно продать не больше заданного количества раз, после чего услуга переносится в архив; и второй режим: можно продавать не больше заданного количества услуг одновременно, с привязкой к записи. Одновременно пользоваться этой услугой смогут только заданное количество гостей, например, ограниченное количество раздевалок или парковочных мест в моменте.',
  6: 'Включите это свойство, если хотите, чтобы услуга имела своё отдельное расписание. Это позволит вам в реальном времени закрывать время самостоятельно или клиентам в виджетах. Вы можете установить время начала раньше основной записи (например, для раздевалок или парковки) и добавить перерыв до или после для подготовки услуги к следующему гостю.',
}
