import { Rule } from 'antd/es/form'
import { isValidPhoneNumber } from 'libphonenumber-js/max'

export function genClientsDataFieldsPhoneValidationRules(): Rule[] {
  return [
    { required: true, message: 'Выберите гостя' },
    ({ getFieldValue }) => ({
      validator(rule, value) {
        if (value.length && !isValidPhoneNumber(value, 'RU')) {
          if (!value.match(/^\d/) || value.includes('+') || value.substring(0, 1) === '8') {
            return Promise.reject('Недопустимое значение')
          }
          return Promise.reject('Кажется это международный номер, начните с +')
        } else {
          return Promise.resolve()
        }
      },
    }),
  ]
}
