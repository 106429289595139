import { ColumnsType } from 'antd/lib/table'
import { Avatar, Tag } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import React from 'react'

import { TableCellText } from '../../table-cells/table-cell-text/table-cell-text.component'
import { formatPhoneNumber } from '../../../format/phone.format'
import { formatRubleCurrency } from '../../../format/number.format'
import { ContractsClientsTableActions } from './contracts-clients-table-action/contracts-clients-table-action.component'
import { ContractsClientTableRow } from './contracts-clients-table.types'

export interface ContractsInfoServicesTableActions {
  onInfo: (id: string) => void
  onRemove: (id: string) => void
}

export function genClientsInfoTableColumns(
  params: ContractsInfoServicesTableActions
): ColumnsType<ContractsClientTableRow> {
  const { onInfo, onRemove } = params

  return [
    {
      title: 'Фото',
      dataIndex: 'photo',
      key: 'photo',
      render: photo => <Avatar size="large" shape="square" src={photo} icon={<UserOutlined />} />,
    },
    {
      title: 'ФИО',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Телефон',
      dataIndex: 'phone',
      key: 'phone',
      render: (_, { phone }) => <TableCellText text={formatPhoneNumber(`+${phone}`)} />,
    },
    {
      title: 'Последнее посещение',
      dataIndex: 'lastVisit',
      key: 'lastVisit',
      render: (_, { lastVisit }) => <TableCellText text={lastVisit} />,
    },
    {
      title: 'Категория',
      dataIndex: 'category',
      key: 'category',
      render: (_, { category }) => <Tag>{category?.name}</Tag>,
    },
    {
      title: 'Оборот',
      dataIndex: 'turnover',
      key: 'turnover',
      render: (_, { turnover }) => <TableCellText text={formatRubleCurrency(turnover)} />,
    },
    {
      title: 'Операции',
      key: 'action',
      render: (_, client) => (
        <ContractsClientsTableActions id={client.id} name={client.name} onInfo={onInfo} onRemove={onRemove} />
      ),
    },
  ]
}
