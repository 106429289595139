import { Form } from 'antd'
import { DefaultOptionType } from 'antd/es/select'
import dayjs from 'dayjs'
import { useCallback, useEffect, useState } from 'react'

import { DictionaryItem } from '../../../../api/types/api.types'
import { useTheme } from '../../../../hooks/use-theme.hook'
import { isDefAndNotEmpty } from '../../../../types/lang.types'
import {
  ScheduleModalConflictItem,
  ScheduleModalConflictsExerciseValues,
  ScheduleModalConflictsFormValues,
} from './schedule-modal-conflicts-exercise.types'
import { getScheduleModalConflictsExerciseValues } from './schedule-modal-conflicts-exercise.utils'

export interface UseScheduleModalConflictsExerciseProps {
  conflict: ScheduleModalConflictItem
  trainersOptions?: DefaultOptionType[]
  studioOffset?: number
  onSaveHandler: (
    exerciseId: string,
    timeFrom: string,
    timeTo: string,
    values?: ScheduleModalConflictsExerciseValues
  ) => void
}

export function useScheduleModalConflictsExercise(props: UseScheduleModalConflictsExerciseProps) {
  const { conflict, trainersOptions, studioOffset, onSaveHandler } = props
  const { exercise } = conflict

  const { theme } = useTheme()

  const offset = studioOffset || 0

  const timeFrom = dayjs(exercise.timeFrom).utcOffset(offset).format('HH:mm')
  const timeTo = dayjs(exercise.timeTo).utcOffset(offset).format('HH:mm')

  const trainerNames = exercise.trainers?.map(trainer => trainer.name).join(', ')

  const [form] = Form.useForm()

  const hasTrainersInConflict = isDefAndNotEmpty(conflict.conflicts?.trainers)
  const hasRoomsInConflict = isDefAndNotEmpty(conflict.conflicts?.rooms)

  const [trainersList, setTrainersList] = useState<(DictionaryItem<string> | undefined)[]>([])

  const onRemoveTrainer = useCallback(
    (index: number) => {
      const updatedTrainersList = [...trainersList]
      updatedTrainersList.splice(index, 1)

      const values = form.getFieldsValue()
      values.trainers?.splice(index, 1)

      setTrainersList(updatedTrainersList)

      form.setFieldsValue({
        ...values,
        noTrainer: updatedTrainersList.length === 0,
        trainers: values.trainers,
      })
    },
    [form, trainersList]
  )

  const onFinish = useCallback(
    (values: ScheduleModalConflictsFormValues) => {
      const exerciseId = exercise.id
      const exerciseValues = getScheduleModalConflictsExerciseValues(values, conflict, trainersOptions)
      onSaveHandler(exerciseId, exercise.timeFrom, exercise.timeTo, exerciseValues)
    },
    [conflict, onSaveHandler, trainersOptions]
  )

  useEffect(() => {
    if (conflict?.conflicts) {
      form.setFieldsValue({
        ...(isDefAndNotEmpty(conflict.conflicts.rooms) && {
          room: conflict.conflicts.rooms?.[0].room?.id,
        }),
        ...(isDefAndNotEmpty(conflict.conflicts.trainers) && {
          trainers: [],
          noTrainer: false,
        }),
      })
    }
  }, [form, conflict, exercise.roomId, exercise.trainers])

  useEffect(() => {
    if (conflict.conflicts?.trainers) {
      const uniqueTrainers = conflict.conflicts.trainers
        .flatMap(trainer => trainer.trainers)
        .filter((trainer, index, self) => index === self.findIndex(t => t?.id === trainer?.id))
      setTrainersList(uniqueTrainers)
      form.setFieldsValue({ trainers: uniqueTrainers.map(() => null) })
    }
  }, [conflict.conflicts?.trainers, form])

  return {
    theme,
    form,
    timeFrom,
    timeTo,
    trainerNames,
    hasTrainersInConflict,
    hasRoomsInConflict,
    trainersList,
    onRemoveTrainer,
    onFinish,
  }
}
