import { Modal } from 'antd'
import * as React from 'react'

import { ExercisesGroupBookingForm } from '../exercises-group-booking-form/exercises-group-booking-form.component'
import { ExercisesGroupBookingModalProps } from './exercises-group-booking-modal.types'

export const ExercisesGroupBookingModal: React.FC<ExercisesGroupBookingModalProps> = props => {
  const { form, placesOptions, loading, exerciseId } = props
  const { onCancel, onSave } = props

  return (
    <Modal title="Какого гостя записать?" open={true} onCancel={onCancel} footer={null}>
      <ExercisesGroupBookingForm
        form={form}
        loading={loading}
        placesOptions={placesOptions}
        exerciseId={exerciseId}
        onSave={onSave}
      />
    </Modal>
  )
}
