import React from 'react'
import { Breadcrumb, Flex, Typography } from 'antd'
import { Link } from 'react-router-dom'

import { formatRubleCurrency } from '../../../format/number.format'
import { useContractsInfoHeader } from './contracts-info-page-header.hook'
import { CardStatistic } from '../../../components/contracts/contracts-card-statistic/contracts-card-statistic.component'
import style from './contracts-info-page-header.module.scss'

export const ContractsInfoHeader = () => {
  const { counterparty, totalTurnover, debit, credit } = useContractsInfoHeader()
  const turnover = formatRubleCurrency(totalTurnover)
  const totalDebit = formatRubleCurrency(debit)
  const totalCredit = formatRubleCurrency(credit)

  return (
    <Flex vertical gap={'large'}>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/contracts">Контрагенты</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          карточка {counterparty?.type === 'COMPANY' ? counterparty?.companyName : counterparty?.directorName}
        </Breadcrumb.Item>
      </Breadcrumb>
      <Flex>
        <Typography.Title level={2} className={style.title}>
          {counterparty?.type === 'COMPANY' ? counterparty?.companyName : counterparty?.directorName}
        </Typography.Title>
      </Flex>
      <Flex gap={5}>
        <CardStatistic value={counterparty?.clientCount || 0} title={'Сотрудники'} classNames={style.infoStatistic} />
        {counterparty?.activeContracts?.length !== 0 && (
          <>
            <CardStatistic value={totalDebit} title={'Дебет'} classNames={style.infoStatistic} />
            <CardStatistic value={totalCredit} title={'Кредит'} classNames={style.infoStatistic} />
            <CardStatistic value={turnover} title={'Оборот'} classNames={style.infoStatistic} />
          </>
        )}
      </Flex>
    </Flex>
  )
}
