import React from 'react'
import { Flex, Pagination } from 'antd'
import { PaginationConfig } from 'antd/es/pagination'

import { PageLoader } from '../../../components/page/page-loader/page-loader.component'
import { PageEmpty } from '../../../components/page/page-empty/page-empty.component'
import { useContractsPageList } from './contracts-card-list.hook'
import { ContractsCard } from '../contracts-card/contracts-card.component'
import style from './contracts-card-list.module.scss'

interface ContractsCardListProps {
  pagination?: PaginationConfig
  onChangePage: (page: number, pageSize: number) => void
  onChangePageSize: (pageSize: number) => void
}

export const ContractsCardList = ({ pagination, onChangePageSize, onChangePage }: ContractsCardListProps) => {
  const { counterparties, isLoading } = useContractsPageList()

  if (isLoading) {
    return <PageLoader />
  }

  if (!isLoading && (!counterparties || counterparties.length === 0)) {
    return <PageEmpty description="Нет контрагентов" />
  }

  return (
    <>
      <div className={style.containerContractsCards}>
        {!isLoading && counterparties?.map(counterparty => <ContractsCard key={counterparty.id} {...counterparty} />)}
      </div>
      <Flex style={{ width: '100%' }} justify={'center'}>
        <Pagination {...pagination} onChange={onChangePage} onShowSizeChange={onChangePageSize} disabled={isLoading} />
      </Flex>
    </>
  )
}
