import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { Dayjs } from 'dayjs'

import { formatDayjsToDate } from '../../../../format/date.format'
import { isDef } from '../../../../types/lang.types'
import { ContractsApi } from '../../../../api/types/contracts-api.types'
import { contractsInfoPageDescriptionActions } from '../../../../store/pages/contracts-info-page/contracts-info-page-description/contracts-info-page-description.slice'

export const useDescriptionTabs = () => {
  const dispatch = useDispatch()

  function genCounterpartyDTO(values: ContractsApi.CounterpartiesDTO<Dayjs>): ContractsApi.CounterpartiesDTO {
    const { birthDate, passportIssueDate, ...restValues } = values

    return {
      ...restValues,
      birthDate: isDef(birthDate) ? formatDayjsToDate(birthDate) : null,
      passportIssueDate: isDef(passportIssueDate) ? formatDayjsToDate(passportIssueDate) : null,
    }
  }

  const onEditCounterpartyHandler = useCallback(
    (id: string, clientsFormValues: ContractsApi.CounterpartiesDTO<Dayjs>): void => {
      const formattedValue = genCounterpartyDTO(clientsFormValues)
      dispatch(
        contractsInfoPageDescriptionActions.updateCounterpartiesById({
          id,
          data: formattedValue,
        })
      )
    },
    [dispatch]
  )

  return {
    onEditCounterpartyHandler,
  }
}
