import { useSelector } from 'react-redux'

import {
  getCounterpartyByIdDebitAndCredit,
  getCounterpartyByIdInternal,
  getCounterpartyTotalTurnover,
} from '../../../store/pages/contracts-info-page/contracts-info-page-description/contracts-info-page-description.selectors'

export function useContractsInfoHeader() {
  const counterparty = useSelector(getCounterpartyByIdInternal)
  const totalTurnover = useSelector(getCounterpartyTotalTurnover)
  const { debit, credit } = useSelector(getCounterpartyByIdDebitAndCredit)

  return {
    counterparty,
    totalTurnover,
    debit,
    credit,
  }
}
