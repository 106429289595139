import React, { useEffect, useState } from 'react'
import { Button, Flex, Form, Input } from 'antd'
import { useParams } from 'react-router-dom'
import { Dayjs } from 'dayjs'

import { useContractsInfoBankTabs } from './contracts-info-tabs-bank.hook'
import { ContractsApi } from '../../../../../api/types/contracts-api.types'

interface BankTabsProps {
  onHandleEdit: (id: string, values: ContractsApi.CounterpartiesDTO<Dayjs>) => void
}

export const BankTabs = ({ onHandleEdit }: BankTabsProps) => {
  const { id } = useParams<{ id: string }>()
  const { form, counterpartyFormValues } = useContractsInfoBankTabs()
  const [isModified, setIsModified] = useState(false)
  const [initialFormState, setInitialFormState] = useState<ContractsApi.CounterpartiesDTO>()

  function validateNumericInput(event: React.KeyboardEvent<HTMLInputElement>) {
    !/[0-9]/.test(event.key) && event.preventDefault()
  }

  useEffect(() => {
    setInitialFormState(form.getFieldsValue())
  }, [form, counterpartyFormValues])

  function handleOnChange() {
    const currentValue = form.getFieldsValue()
    setIsModified(JSON.stringify(initialFormState) !== JSON.stringify(currentValue))
  }

  function handleEditCounterparty(values: ContractsApi.CounterpartiesDTO<Dayjs>) {
    if (isModified) {
      onHandleEdit(id, values)
      setInitialFormState(form.getFieldsValue())
      setIsModified(false)
    }
  }

  return (
    <Form
      autoComplete="off"
      form={form}
      style={{ marginTop: '24px' }}
      layout="vertical"
      name="form_in_modal"
      initialValues={{
        modifier: 'public',
      }}
      onFinish={handleEditCounterparty}
      onChange={handleOnChange}
    >
      <Flex vertical gap={'large'}>
        <Form.Item
          name="bankName"
          label="Наименование банка"
          rules={[
            {
              required: true,
              message: 'Введите Наименование банка!',
            },
          ]}
        >
          <Input type="textarea" />
        </Form.Item>
        <Form.Item
          name="bankAccount"
          label="Расчётный счёт"
          rules={[
            {
              required: true,
              message: 'Введите Расчётный счёт!',
            },
            {
              min: 20,
              message: 'Длина Расчётного счёта - 20 символов!',
            },
            {
              max: 20,
              message: 'Длина Расчётного счёта - 20 символов!',
            },
          ]}
        >
          <Input type="textarea" onKeyPress={validateNumericInput} maxLength={20} />
        </Form.Item>
        <Form.Item
          name="bic"
          label="БИК"
          rules={[
            {
              required: true,
              message: 'Введите БИК!',
            },
            {
              min: 9,
              message: 'Длина БИК - 9 символов!',
            },
            {
              max: 9,
              message: 'Длина БИК - 9 символов!',
            },
          ]}
        >
          <Input type="textarea" onKeyPress={validateNumericInput} maxLength={9} />
        </Form.Item>

        <Form.Item
          name="correspondentAccount"
          label="Корреспондентский счёт"
          rules={[
            {
              required: true,
              message: 'Введите Корреспондентский счёт!',
            },
            {
              min: 20,
              message: 'Длина Корреспондентского счёта - 20 символов!',
            },
            {
              max: 20,
              message: 'Длина Корреспондентского счёта - 20 символов!',
            },
          ]}
        >
          <Input type="textarea" onKeyPress={validateNumericInput} maxLength={20} />
        </Form.Item>
      </Flex>
      <Flex justify={'flex-end'}>
        <Button type={'primary'} htmlType="submit" style={{ marginTop: '24px' }} disabled={!isModified}>
          Сохранить изменения
        </Button>
      </Flex>
    </Form>
  )
}
