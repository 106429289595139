import { PlusOutlined, LinkOutlined } from '@ant-design/icons'
import { Form, Button, FormListFieldData, Typography, Flex, FormInstance } from 'antd'
import { DefaultOptionType } from 'antd/es/select'

import { MasterServicesFormValues } from '../../../master-services-form.types'
import { MasterServicesFormSubservicePricingItem } from './master-services-form-subservice-pricing-item/master-services-form-subservice-pricing-item.component'
import './master-services-form-subservice-pricing.styles.less'

interface Props {
  isEdit?: boolean
  form: FormInstance<MasterServicesFormValues>
  field: FormListFieldData
  pricingOptions?: DefaultOptionType[]
  onOpenPricingModal: (id?: string, key?: number) => void
  onDisconnectPricing?: (key: number, pricingId: string) => void
  onRemovePricing?: (id: string) => void
  onCopyPricing?: (pricingId: string, field: number, subField: number) => void
}

export const MasterServicesFormSubservicePricing: React.FC<Props> = props => {
  const { field, onOpenPricingModal, pricingOptions, ...rest } = props

  return (
    <Flex vertical gap="middle">
      <Flex vertical gap="small">
        <Typography.Title level={5} className="master-services-form-subservice-pricing__subtitle">
          Активное ценообразование
        </Typography.Title>
      </Flex>
      <Form.List name={[field.name, 'pricing']}>
        {(subFields, { add, remove }) => (
          <Flex vertical gap="small">
            {subFields.map(subField => (
              <MasterServicesFormSubservicePricingItem
                key={subField.key}
                {...rest}
                pricingOptions={pricingOptions}
                field={field}
                subField={subField}
                onOpenPricingModal={onOpenPricingModal}
                onRemoveSubField={remove}
              />
            ))}
            <Flex gap="middle" style={{ marginTop: !!subFields.length ? '5px' : '0px' }}>
              {!!pricingOptions?.length && (
                <Button onClick={() => add()} icon={<LinkOutlined />}>
                  Выбрать готовое
                </Button>
              )}
              <Button icon={<PlusOutlined />} onClick={() => onOpenPricingModal(undefined, field.name)}>
                Создать новое ценообразование
              </Button>
            </Flex>
          </Flex>
        )}
      </Form.List>
    </Flex>
  )
}
