import { useSelector } from 'react-redux'

import {
  getCounterpartiesCardsList,
  getCounterpartiesIsLoading,
} from '../../../store/pages/contracts-page/contracts-page.selectors'

export function useContractsPageList() {
  const counterparties = useSelector(getCounterpartiesCardsList)
  const isLoading = useSelector(getCounterpartiesIsLoading)

  return {
    counterparties,
    isLoading,
  }
}
