import { api } from '@api/api'
import { genPaymentTypesOptions } from '@utils/payment.utils'
import { Button, Flex, Form, Select } from 'antd'
import { DefaultOptionType } from 'antd/es/select'
import { FC, useEffect, useState } from 'react'

import { ClientsDataFields } from '../../clients/clients-data-fields/clients-data-fields.component'
import { useExercisesGroupBookingForm } from './exercises-group-booking-form.hook'
import { ExercisesGroupBookingFormProps } from './exercises-group-booking-form.types'

export const ExercisesGroupBookingForm: FC<ExercisesGroupBookingFormProps> = props => {
  // Props
  const { form, loading, exerciseId } = props
  const { placesOptions } = props
  const { onSave } = props

  // State
  const [paymentTypesOptions, setPaymentTypesOptions] = useState<DefaultOptionType[]>([])
  const [isLoadingClient, setIsLoadingClient] = useState(false)

  // Hooks
  const phone = Form.useWatch('phone', form)

  // Custom hooks
  const { placeValidationRules, paymentTypeValidationRules, setClientCurrentData, onFinishHandler } =
    useExercisesGroupBookingForm({
      form,
      onSave,
    })

  // Lifecycle
  useEffect(() => {
    ;(async () => {
      if (!phone) {
        setPaymentTypesOptions([])
      } else {
        const phoneNumber = phone?.replace(/\D/g, '')
        const response = await api.exercisesService.fetchPaymentTypes(exerciseId, phoneNumber)
        const types = genPaymentTypesOptions(response?.data, true)
        setPaymentTypesOptions(types)
      }
    })()
  }, [phone, exerciseId])

  // Render
  return (
    <Form
      form={form}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      autoComplete="off"
      layout="vertical"
      onFinish={onFinishHandler}
    >
      <Flex gap={15} style={{ flexDirection: 'column', marginTop: 15 }}>
        <ClientsDataFields
          phoneFieldLabel={<span>Номер телефона</span>}
          form={form}
          isLoading={loading || isLoadingClient}
          setIsLoading={setIsLoadingClient}
          setClientData={setClientCurrentData}
        />

        <Form.Item label="Место" name="place" rules={placeValidationRules}>
          <Select placeholder="Место" options={placesOptions} disabled={loading} loading={loading} />
        </Form.Item>

        <Form.Item label="Способ оплаты" name="paymentType" rules={paymentTypeValidationRules}>
          <Select placeholder="Способ оплаты" options={paymentTypesOptions} disabled={loading} loading={loading} />
        </Form.Item>
        <Form.Item>
          <Flex justify="end" align="center" gap="small">
            <Button type="primary" htmlType="submit" disabled={loading || isLoadingClient}>
              Сохранить
            </Button>
          </Flex>
        </Form.Item>
      </Flex>
    </Form>
  )
}
