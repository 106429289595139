import { createSelector } from '@reduxjs/toolkit'

import { EmployeesApi } from '../../../api/types/employees-api.types'
import { ContractsApi } from '../../../api/types/contracts-api.types'
import {
  getCounterpartyDebitAndCredit,
  getCounterpartiesTotalTurnover,
  mapManagersToDropdown,
  mapCounterpartiesCard,
} from '../../../mapping/contracts.mapping'
import { Pagination } from '../../../api/types/api.types'
import { Nullable } from '../../../types/lang.types'
import { AppState } from '../../app.store'

const getCounterpartiesInternal = (state: AppState): Nullable<Pagination<ContractsApi.Counterparties>> =>
  state.contractsPage.counterparties

const getManagersInternal = (state: AppState): Nullable<Pagination<EmployeesApi.Employee>> =>
  state.contractsPage.managers

export const getCounterpartiesIsLoading = (state: AppState): boolean => state.contractsPage.isLoading

export const getCounterpartiesIsCreating = (state: AppState): boolean => state.contractsPage.isCreating

const getTotalCounterpartiesTurnover = createSelector(getCounterpartiesInternal, counterparties =>
  getCounterpartiesTotalTurnover(counterparties?.content)
)

export const getCounterpartyTotalDebitAndCredit = createSelector(getCounterpartiesInternal, counterparties =>
  getCounterpartyDebitAndCredit(counterparties?.content)
)

export const getCounterpartiesCardsList = createSelector(
  getCounterpartiesInternal,
  getCounterpartyTotalDebitAndCredit,
  getTotalCounterpartiesTurnover,
  (counterparties, counterpartiesDebitAndCredit, totalTurnover) =>
    mapCounterpartiesCard(counterparties?.content, counterpartiesDebitAndCredit, totalTurnover)
)

export const getManagersCardsList = createSelector(getManagersInternal, managers =>
  mapManagersToDropdown(managers?.content)
)

export const getCounterpartiesTotalElements = createSelector(
  getCounterpartiesInternal,
  counterparty => counterparty?.totalElements
)
