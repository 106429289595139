import React from 'react'
import { Avatar, Card, Flex, Typography } from 'antd'
import { MailOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'

import { formatRubleCurrency } from '../../../format/number.format'
import { formatPhoneNumber } from '../../../format/phone.format'
import { genContractsInfoPagePath } from '../../../format/path.format'
import { ContractsCardProps } from './contracts-card.types'
import { CardStatistic } from '../../../components/contracts/contracts-card-statistic/contracts-card-statistic.component'
import style from './contracts-card.module.scss'

export const ContractsCard = ({
  id,
  telephone,
  email,
  companyName,
  directorName,
  totalTurnover,
  debit,
  credit,
  clientCount = 0,
  isActiveContracts,
  type,
}: ContractsCardProps) => {
  const telephoneNumber = formatPhoneNumber(`${telephone}`)
  const turnover = formatRubleCurrency(totalTurnover)
  const totalDebit = formatRubleCurrency(debit)
  const totalCredit = formatRubleCurrency(credit)

  return (
    <Card
      hoverable
      actions={[
        <Flex gap={'middle'} vertical className={style.cardActions}>
          <Flex gap={'small'} vertical style={{ width: '280px' }}>
            <Flex gap={'small'}>
              <Flex gap={'small'} style={{ width: '100%' }}>
                <MailOutlined />
                <Typography.Link href={`mailto:${email}`} ellipsis>
                  {email || 'Email не указан'}
                </Typography.Link>
              </Flex>
              <Flex gap={'small'} style={{ width: '100%' }}>
                <PhoneOutlined />
                <Typography.Link href={`tel:${telephone}`} ellipsis>
                  {telephoneNumber || 'Телефон не указан'}
                </Typography.Link>
              </Flex>
            </Flex>
          </Flex>
        </Flex>,
      ]}
    >
      <Link to={genContractsInfoPagePath({ id })}>
        <Flex className={style.header} gap={'middle'} justify={'space-between'}>
          {/*<Avatar icon={<UserOutlined />} />*/}
          <Flex className={style.nameContainer} justify={'space-between'} align={'center'}>
            <Typography.Title level={5} className={style.organizationName}>
              {type === 'COMPANY' ? companyName : directorName}
            </Typography.Title>
            <Typography.Text type={'secondary'}>{type === 'COMPANY' ? directorName : 'Физ. лицо'}</Typography.Text>
          </Flex>
        </Flex>
        <Flex justify={'space-around'} gap={'small'}>
          <CardStatistic title="Сотрудники" value={clientCount} />
          {isActiveContracts && (
            <>
              <CardStatistic title="Дебет" value={totalDebit} />
              <CardStatistic title="Кредит" value={totalCredit} />
              <CardStatistic title="Оборот" value={turnover} />
            </>
          )}
        </Flex>
      </Link>
    </Card>
  )
}
