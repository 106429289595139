import { FormInstance } from 'antd'
import { isValidPhoneNumber } from 'libphonenumber-js'
import { useCallback, useMemo, useState } from 'react'

import { api } from '../../../api/api'
import { ClientsApi } from '../../../api/types/clients-api.types'
import { Nullable } from '../../../types/lang.types'
import { genClientsDataFieldsPhoneValidationRules } from './clients-data-fields.utils'

interface Props {
  form: FormInstance<any>
  setIsLoading: (isLoading: boolean) => void
  setClientData: (value: React.SetStateAction<Nullable<ClientsApi.Client>>) => void
}

export function useClientsDataFields(props: Props) {
  const { form, setIsLoading, setClientData } = props

  const phoneValidationRules = useMemo(genClientsDataFieldsPhoneValidationRules, [])

  const [isIncompleteMessageShown, setIsIncompleteMessageShown] = useState(false)
  const [userMissingFields, setUserMissingFields] = useState<string[]>([])
  const [isAdditionalFieldsShown, setIsAdditionalFieldsShown] = useState(false)

  const resetClientFields = useCallback(() => {
    form.resetFields(['firstName', 'lastName', 'email', 'clientId'])
  }, [form])

  const resetClientState = useCallback(() => {
    setIsAdditionalFieldsShown(false)
    setIsIncompleteMessageShown(false)
    setUserMissingFields([])
  }, [])

  const checkClientMissingFields = useCallback(
    (clientData: { firstName?: string; lastName?: string; email?: string }) => {
      const missing = ['firstName', 'lastName', 'email'].filter(field => !clientData[field as keyof typeof clientData])
      setUserMissingFields(missing)
      setIsIncompleteMessageShown(missing.length > 0)
    },
    []
  )

  const fetchClientById = useCallback(
    async (clientId: string) => {
      setIsLoading(true)
      try {
        const response = await api.clientsService.fetchById(clientId)
        if (response?.data) {
          setClientData(response?.data)
          checkClientMissingFields(response.data)
        }
      } catch (error) {
        console.error('Error fetching client:', error)
      } finally {
        setIsLoading(false)
      }
    },
    [checkClientMissingFields, setClientData, setIsLoading]
  )

  const onFillIncompleteClientData = useCallback(() => {
    setIsIncompleteMessageShown(false)
    setIsAdditionalFieldsShown(true)
  }, [])

  const onHideAdditionalFields = useCallback(() => {
    setIsAdditionalFieldsShown(false)
    setIsIncompleteMessageShown(true)
    resetClientFields()
  }, [resetClientFields])

  const onSetClientId = useCallback(
    async (clientId: string | null) => {
      resetClientState()
      resetClientFields()
      if (clientId) {
        form.setFieldValue('clientId', clientId)
        fetchClientById(clientId)
      }
    },
    [resetClientState, resetClientFields, form, fetchClientById]
  )

  const onAddNewClient = useCallback(() => {
    resetClientFields()
    setClientData(null)
    setIsIncompleteMessageShown(false)
    setIsAdditionalFieldsShown(true)
    setUserMissingFields(['firstName', 'lastName', 'email'])
  }, [resetClientFields, setClientData])

  const onValidateClientPhone = useCallback(
    (value?: string, clientId?: string) => {
      resetClientFields()
      resetClientState()
      setClientData(null)
      if (value && isValidPhoneNumber(value, 'RU')) {
        if (clientId) {
          form.setFieldValue('clientId', clientId)
          fetchClientById(clientId)
        } else {
          setIsAdditionalFieldsShown(true)
          setUserMissingFields(['firstName', 'lastName', 'email'])
        }
      }
    },
    [fetchClientById, form, resetClientFields, resetClientState, setClientData]
  )

  return {
    phoneValidationRules,
    isAdditionalFieldsShown,
    isIncompleteMessageShown,
    userMissingFields,
    onSetClientId,
    onAddNewClient,
    onValidateClientPhone,
    onFillIncompleteClientData,
    onHideAdditionalFields,
  }
}
