import * as React from 'react'
import { Form } from 'antd'

import { api } from '../../api/api'
import { isDef, Nullable } from '../../types/lang.types'
import { mapClientsToClientsAutocompleteOptionDataItems } from '../../mapping/clients.mapping'
import { ClientsAutocompleteOptionDataItem } from '../../components/clients/clients-autocomplete/clients-autocomplete-option/clients-autocomplete-option.types'
import { ClientsAutocompleteProps } from './clients-autocomplete.types'
import { useTransactionsCreatePageTable } from '../../pages/transactions-create-page/transactions-create-page-table/transactions-create-page-table.hook'
import { SearchApi } from '../../api/types/search-api.types'
import { normalizePhone } from '../../format/phone.format'

export function useClientsAutocomplete<FormValues>(props: ClientsAutocompleteProps<FormValues>) {
  const { form, name, transactionsForm, onChange, onBlur, setClientId } = props

  const { onChangeTrialHandler } = useTransactionsCreatePageTable()

  const value = Form.useWatch(name, form)

  const [clients, setClients] = React.useState<SearchApi.SearchItem[]>([])
  const [loading, setLoading] = React.useState<boolean>(false)

  React.useEffect((): void => {
    if (clients?.length === 0 && transactionsForm) {
      onChangeTrialHandler(null)
    }
  }, [clients])

  const options = React.useMemo(() => mapClientsToClientsAutocompleteOptionDataItems(clients), [clients])

  const onChangeHandler = React.useCallback(
    (value?: string): void => {
      if (onChange) onChange(value)
      form.setFieldValue(name, value)
    },
    [form, name, onChange]
  )

  const onBlurHandler = React.useCallback(() => {
    const normalizedValue = normalizePhone(value || '')
    const clientId = options?.find(option => option.phone.includes(normalizedValue))?.slug

    if (onBlur) onBlur(value, clientId)

    form.validateFields([name])
  }, [value, options, onBlur, form, name])

  const onFocusHandler = React.useCallback(() => {
    form.setFields([
      {
        name: name,
        errors: [],
      },
    ])
  }, [form, name])

  const onSelectHandler = React.useCallback(
    (option: Nullable<ClientsAutocompleteOptionDataItem>): void => {
      if (isDef(option)) {
        form.setFieldValue(name, option.phone)
        form.setFieldValue('clientId', option.slug)

        if (setClientId) {
          setClientId(option.slug)
        }
        if (transactionsForm) {
          onChangeTrialHandler(option.slug)
        }
      }
    },
    [form, name]
  )

  const onSearchHandler = React.useCallback((value?: string): void => {
    setLoading(true)

    api.clientsService
      .search(value ?? '')
      .then((response): void => {
        setClients(response?.data ?? [])
      })
      .catch(error => console.error(error))
      .finally(() => setLoading(false))
  }, [])

  return {
    value,
    options,
    loading,
    onChangeHandler,
    onBlurHandler,
    onFocusHandler,
    onSearchHandler,
    onSelectHandler,
  }
}
