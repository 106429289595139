import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getCounterpartiesIsLoading } from '../../store/pages/contracts-page/contracts-page.selectors'
import { contractsPageActions } from '../../store/pages/contracts-page/contracts-page.slice'

export const useCounterpartiesSearch = () => {
  const dispatch = useDispatch()
  const isLoading = useSelector(getCounterpartiesIsLoading)

  const [searchValue, setSearchValue] = useState<string | undefined>()

  const onSearchHandler = useCallback(
    (value: string): void => {
      dispatch(contractsPageActions.searchCounterparties({ params: value }))
    },
    [dispatch]
  )

  return {
    searchValue,
    isLoading,
    onSearchHandler,
  }
}
