import React from 'react'
import { Flex } from 'antd'

import { useContractsPage } from './contracts-page.hook'
import { ContractsHeader } from './contracts-page-header/contracts-page-header.component'
import { ContractsCardList } from './contracts-card-list/contracts-card-list.component'

export const ContractsPage = () => {
  const { pagination, onChangePageSizeHandler, onChangePageHandler } = useContractsPage()

  return (
    <Flex vertical gap={'large'} style={{ height: '100%' }}>
      <ContractsHeader />
      <ContractsCardList
        pagination={pagination}
        onChangePageSize={onChangePageSizeHandler}
        onChangePage={onChangePageHandler}
      />
    </Flex>
  )
}
