import { TeamOutlined, UserOutlined } from '@ant-design/icons'
import { QuestionTooltip } from '@components/ui/question-tooltip/question-tooltip.component'
import { useTheme } from '@hooks/use-theme.hook'
import { Button, Col, Flex, Form, FormInstance, Input, Radio, Select, Space, Switch, Typography } from 'antd'
import { RadioChangeEvent } from 'antd/lib'
import { DefaultOptionType } from 'antd/lib/select'
import { useState } from 'react'

import { useProductServicesForm } from './product-services-form.hook'
import { ServiceFormValues } from './product-services-form.types'
import {
  genProductServicesQuestions,
  genServicesInitialValues,
  validateProductsServicesNoSpacesOnly,
  validateProductsServicesNumberInput,
} from './product-services-form.utils'
import './product-services.styles.less'

interface Props {
  form: FormInstance<ServiceFormValues>
  onFinish: (values: ServiceFormValues) => void
  submitText: string
  studiosOptions?: DefaultOptionType[]
  exercisesOptions?: DefaultOptionType[]
  disabledFormat: boolean
}

export const ProductServicesForm = ({
  form,
  onFinish,
  submitText,
  studiosOptions,
  exercisesOptions,
  disabledFormat,
}: Props) => {
  const { theme } = useTheme()

  const [valueRadio, setValueRadio] = useState('BOOKING_BIND')
  const onChangeRadio = (e: RadioChangeEvent) => {
    setValueRadio(e.target.value)
  }

  // @ts-ignore
  const { filteredDirectionsOptions, filteredOptions } = useProductServicesForm(exercisesOptions, form)

  const tooltips = genProductServicesQuestions
  return (
    <Form
      form={form}
      name="serviceCreate"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      onFinish={onFinish}
      autoComplete="off"
      layout="vertical"
      initialValues={genServicesInitialValues()}
    >
      <div className={`product-services ${theme === 'light' ? 'product-services-light' : 'product-services-dark'}`}>
        <Col>
          <Typography.Title level={4}>Основный настройки</Typography.Title>
          <Form.Item
            style={{ paddingTop: 15 }}
            label="Название услуги"
            name="name"
            rules={[{ required: true, validator: validateProductsServicesNoSpacesOnly }]}
          >
            <Input size="large" />
          </Form.Item>
          <Form.Item style={{ paddingTop: 15 }} label="Описание" name="description">
            <Input.TextArea size="large" />
          </Form.Item>
        </Col>

        <Col style={{ paddingTop: 15 }}>
          <Typography.Title level={5}>Формат</Typography.Title>
          <Col style={{ paddingTop: 15 }}>
            <Form.Item name="format" rules={[{ required: true, message: 'Выберите формат услуги' }]}>
              <Radio.Group disabled={disabledFormat} onChange={onChangeRadio} value={valueRadio}>
                <Space direction="vertical">
                  <Flex>
                    <Radio value="BOOKING_BIND">
                      <Flex gap={8}>
                        Обычная разовая услуга
                        <QuestionTooltip title={tooltips[1]} />
                      </Flex>
                    </Radio>
                  </Flex>

                  <Radio value="BOOKING_PAYMENT">
                    <Flex gap={8}>
                      Услуга для оплаты посещения / записи
                      <QuestionTooltip title={tooltips[2]} />
                    </Flex>
                  </Radio>
                  <Radio value="EXECUTOR_PAYMENT" disabled={true}>
                    <Flex gap={8}>
                      Услуга для оплаты исполнителя
                      <QuestionTooltip title={tooltips[3]} />
                    </Flex>
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Col>

        <Col style={{ paddingTop: 10 }}>
          <Typography.Title level={5}>Стоимость</Typography.Title>
          <Flex gap={15} style={{ paddingTop: 15 }}>
            <Col flex="1">
              <Form.Item
                label="Сумма"
                name="cost"
                rules={[{ validator: validateProductsServicesNumberInput }]}
                validateTrigger="onChange"
              >
                <Input type="number" size="large" suffix="₽" />
              </Form.Item>
            </Col>
            <Col flex="1">
              <Flex style={{ paddingBottom: 8 }} gap={8}>
                <Typography>Стоимость пробного</Typography>
                <QuestionTooltip title={tooltips[4]} />
              </Flex>
              <Form.Item name="trialCost" rules={[{ validator: validateProductsServicesNumberInput }]}>
                <Input type="number" size="large" suffix="₽" />
              </Form.Item>
            </Col>
          </Flex>
        </Col>

        <Flex align="center" gap={5} style={{ paddingTop: 15 }}>
          <Switch disabled={true} size="small" />
          <Flex gap={8}>
            <Typography style={{ color: 'grey' }}>Услуга с ограниченным количеством</Typography>
            <QuestionTooltip title={tooltips[5]} />
          </Flex>
        </Flex>
        <Flex style={{ paddingTop: 15 }} align="center" gap={5}>
          <Switch disabled={true} size="small" />
          <Flex gap={8}>
            <Typography style={{ color: 'grey' }}>Услуга со своим расписанием</Typography>
            <QuestionTooltip title={tooltips[6]} />
          </Flex>
        </Flex>
        <Col style={{ paddingTop: 15 }}>
          <Typography.Title level={4}>Условия использования</Typography.Title>
          <Col style={{ paddingTop: 15 }}>
            <Form.Item name="availableStudios" label="Локации">
              <Select size="large" placeholder="Все" options={studiosOptions} mode="multiple" labelInValue />
            </Form.Item>
          </Col>
          <Col style={{ paddingTop: 15 }}>
            <Form.Item
              name="availableTypes"
              label="Категория записи"
              rules={[{ required: true, message: 'Выберите категорию записи' }]}
            >
              <Select
                size="large"
                mode="multiple"
                placeholder="Выберите категорию записи"
                options={filteredOptions?.map(option => {
                  let icon
                  if (option.format === 'PERSONAL') {
                    icon = <UserOutlined style={{ color: '#000' }} />
                  } else {
                    icon = <TeamOutlined style={{ fontSize: 16, color: '#000' }} />
                  }
                  return {
                    value: option.value,
                    label: (
                      <Flex align="center" gap={6}>
                        <div>{icon}</div>
                        {/* @ts-ignore */}
                        <Typography>{option.label}</Typography>
                      </Flex>
                    ),
                  }
                })}
                labelInValue
                filterOption={(input, option) =>
                  option?.label.props.children[1].props.children.toLowerCase().includes(input.toLowerCase())
                }
              />
            </Form.Item>
          </Col>
          {filteredDirectionsOptions.length > 0 && (
            <Col style={{ paddingTop: 15 }}>
              <Form.Item name="availableDirections" label="Направления">
                <Select
                  size="large"
                  placeholder="Все"
                  options={filteredDirectionsOptions}
                  mode="multiple"
                  labelInValue
                />
              </Form.Item>
            </Col>
          )}
        </Col>
        <Button className="product-services__button" type="primary" htmlType="submit">
          {submitText}
        </Button>
      </div>
    </Form>
  )
}
