import * as React from 'react'
import { Button, Flex, Modal, Spin } from 'antd'

import { TransactionsRefundModalProps } from './transactions-refund-modal.types'
import { formatRefundTextByPaymentMethod } from '../../../format/text.format'
import './transactions-refund-modal.styles.less'

export const TransactionsRefundModal: React.FC<TransactionsRefundModalProps> = props => {
  const { loading, loaded } = props
  const { onCancel, onRefund } = props
  const { transactionId, refundSum, paymentMethod } = props

  const onRefundHandler = React.useCallback((): void => {
    onRefund(transactionId)
  }, [transactionId, onRefund])

  return (
    <Modal
      title="Подтверждение возврата"
      visible
      onCancel={onCancel}
      footer={
        <Flex justify="center" gap="middle">
          <Button key="back" disabled={loading} onClick={onCancel}>
            Отменить
          </Button>
          <Button key="submit" type="primary" onClick={onRefundHandler} disabled={!refundSum || loading}>
            Подтверждаю
          </Button>
        </Flex>
      }
    >
      {!loaded || loading ? (
        <Spin className="transactions-refund-modal__spin" />
      ) : refundSum ? (
        formatRefundTextByPaymentMethod(paymentMethod, refundSum)
      ) : (
        'Товары не найдены'
      )}
    </Modal>
  )
}
