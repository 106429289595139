import { useSelector } from 'react-redux'
import { Form } from 'antd'
import React from 'react'

import { isDef } from '../../../../../types/lang.types'
import { getCounterpartyFormValue } from '../../../../../store/pages/contracts-info-page/contracts-info-page-description/contracts-info-page-description.selectors'
import { ContractsApi } from '../../../../../api/types/contracts-api.types'

export const useContractsInfoBankTabs = () => {
  const [form] = Form.useForm<ContractsApi.CounterpartiesDTO<any>>()
  const counterpartyFormValues = useSelector(getCounterpartyFormValue)

  React.useEffect((): void => {
    if (isDef(counterpartyFormValues)) {
      form.setFieldsValue(counterpartyFormValues)
    }
  }, [form, counterpartyFormValues])

  return {
    form,
    counterpartyFormValues,
  }
}
