import { DefaultOptionType } from 'antd/es/select'

import { ConditionTypes } from '../api/types/api.types'
import { EmployeesPositionsApi } from '../api/types/employees-positions-api.types'
import { PricingApi } from '../api/types/pricing.types'
import {
  MasterServicesFormPricing,
  MasterServicesPricingBasePriceFormValues,
  MasterServicesPricingFormValues,
  MasterServicesPricingImpactsFormValues,
} from '../components/master-services/master-services-form/master-services-form.types'
import { isDef, isDefAndNotEmpty, Nullable } from '../types/lang.types'
import { sortOptionsByLocale } from '../utils/options.utils'

export function mapPricingToOptions(pricing: Nullable<PricingApi.Pricing[]>): DefaultOptionType[] | undefined {
  if (isDefAndNotEmpty(pricing)) {
    const options = pricing.map(
      (pricing: PricingApi.Pricing): DefaultOptionType => ({
        value: pricing.id,
        label: pricing.name,
      })
    )
    return sortOptionsByLocale(options)
  }
}

export function mapPricingToConnectedPricing(
  newPricing?: MasterServicesFormPricing[],
  oldPricing?: MasterServicesFormPricing[]
) {
  if (isDefAndNotEmpty(newPricing)) {
    if (isDefAndNotEmpty(oldPricing)) {
      const connectedPricing = newPricing?.filter(
        pricing => !oldPricing?.some(el => el.pricingId === pricing.pricingId)
      )
      return connectedPricing?.map(pricing => ({
        ...pricing,
        activeFrom: pricing.activeFrom.format(),
      }))
    } else {
      return newPricing?.map(pricing => ({
        ...pricing,
        activeFrom: pricing.activeFrom.format(),
      }))
    }
  }
}

export function mapPricingToDisconnectedPricing(
  newPricing?: MasterServicesFormPricing[],
  oldPricing?: MasterServicesFormPricing[]
) {
  if (isDefAndNotEmpty(oldPricing) && isDef(newPricing)) {
    const disconnectedPricing = oldPricing?.filter(
      pricing => !newPricing?.find(el => el.pricingId === pricing.pricingId)
    )
    return disconnectedPricing?.map(pricing => pricing.pricingId)
  }
}

export function mapPricingToUpdatedPricing(
  newPricing?: MasterServicesFormPricing[],
  oldPricing?: MasterServicesFormPricing[]
) {
  if (isDefAndNotEmpty(oldPricing) && isDefAndNotEmpty(newPricing)) {
    const updatedPricing = newPricing?.filter(pricing =>
      oldPricing?.find(el => el.pricingId === pricing.pricingId && !el.activeFrom.isSame(pricing.activeFrom, 'day'))
    )
    return updatedPricing?.map(pricing => ({
      pricingId: pricing.pricingId,
      fromDate: pricing.activeFrom.format(),
    }))
  }
}

function genPricingBasePrices(values: MasterServicesPricingFormValues): PricingApi.BasePrices[] {
  const { priceType, basePrice, value, nameInReceipt } = values

  if (isDefAndNotEmpty(basePrice) && priceType !== ConditionTypes.NONE) {
    switch (priceType) {
      case ConditionTypes.TIME:
        return basePrice.map(price => ({
          valueFrom: price.value,
          valueTo: null,
          condition: {
            type: ConditionTypes.TIME,
            days: price.days,
            timeStart: price.timeStart,
            timeEnd: price.timeEnd === '00:00' ? '23:59' : price.timeEnd,
          },
          nameInReceipt: !price.nameInReceipt?.length ? null : price.nameInReceipt,
        }))
      case ConditionTypes.DURATION:
        return basePrice.map(price => ({
          valueFrom: price.value,
          valueTo: null,
          condition: {
            type: ConditionTypes.DURATION,
            durationMinutes: price.durationMinutes,
          },
          nameInReceipt: !price.nameInReceipt?.length ? null : price.nameInReceipt,
        }))
      case ConditionTypes.GRADE:
        return basePrice.map(price => ({
          valueFrom: price.value,
          valueTo: null,
          condition: {
            type: ConditionTypes.GRADE,
            gradeId: price.gradeId ? price.gradeId : price.positionId,
          },
          nameInReceipt: !price.nameInReceipt?.length ? null : price.nameInReceipt,
        }))
    }
  }

  // ! WARNING: Here we use prices in rubles, because the backend accepts prices in rubles.
  return [
    {
      valueFrom: Number(value),
      valueTo: 0,
      condition: {
        type: ConditionTypes.NONE,
      },
      nameInReceipt: !nameInReceipt?.length ? null : nameInReceipt,
    },
  ]
}

function genPricingPriceImpacts(priceImpacts?: MasterServicesPricingImpactsFormValues[]): {
  [ConditionType in ConditionTypes]?: PricingApi.PriceImpact[]
} {
  let priceImpactsValues = {}
  if (isDefAndNotEmpty(priceImpacts)) {
    priceImpacts.forEach(impact => {
      if (impact.type === ConditionTypes.TIME) {
        const impactConditions = impact.conditions.map(condition => ({
          impactDirection: condition.impactDirection,
          impactType: condition.impactType,
          value: condition.value,
          condition: {
            type: ConditionTypes.TIME,
            days: condition.days,
            timeStart: condition.timeStart,
            timeEnd: condition.timeEnd === '00:00' ? '23:59' : condition.timeEnd,
          },
          nameInReceipt: !condition.nameInReceipt?.length ? null : condition.nameInReceipt,
        }))
        priceImpactsValues = { ...priceImpactsValues, ...{ [ConditionTypes.TIME]: impactConditions } }
      } else if (impact.type === ConditionTypes.DURATION) {
        const impactConditions = impact.conditions.map(condition => ({
          impactDirection: condition.impactDirection,
          impactType: condition.impactType,
          value: condition.value,
          condition: {
            type: ConditionTypes.DURATION,
            durationMinutes: condition.durationMinutes,
          },
          nameInReceipt: !condition.nameInReceipt?.length ? null : condition.nameInReceipt,
        }))
        priceImpactsValues = { ...priceImpactsValues, ...{ [ConditionTypes.DURATION]: impactConditions } }
      } else if (impact.type === ConditionTypes.GRADE) {
        const impactConditions = impact.conditions.map(condition => ({
          impactDirection: condition.impactDirection,
          impactType: condition.impactType,
          value: condition.value,
          condition: {
            type: ConditionTypes.GRADE,
            gradeId: condition.gradeId ? condition.gradeId : condition.positionId,
          },
          nameInReceipt: !condition.nameInReceipt?.length ? null : condition.nameInReceipt,
        }))
        priceImpactsValues = { ...priceImpactsValues, ...{ [ConditionTypes.GRADE]: impactConditions } }
      } else if (impact.type === ConditionTypes.WITH_TRAINER) {
        const impactConditions = impact.conditions.map(condition => ({
          impactDirection: condition.impactDirection,
          impactType: condition.impactType,
          value: condition.value,
          condition: {
            type: ConditionTypes.WITH_TRAINER,
          },
          nameInReceipt: !condition.nameInReceipt?.length ? null : condition.nameInReceipt,
        }))
        priceImpactsValues = { ...priceImpactsValues, ...{ [ConditionTypes.WITH_TRAINER]: impactConditions } }
      }
    })

    return priceImpactsValues
  }
  return priceImpactsValues
}

export function genPricingDTO(values: MasterServicesPricingFormValues): PricingApi.PricingDTO {
  const { name, holidayAsWeekend, priceImpacts } = values

  const createdPricing = {
    name: name,
    holidayAsWeekend: holidayAsWeekend,
    basePrices: genPricingBasePrices(values),
    priceImpacts: genPricingPriceImpacts(priceImpacts),
  }

  return createdPricing
}

function genPricingImpactsFormValues(
  positions: Nullable<EmployeesPositionsApi.EmployeePosition[]>,
  priceImpacts?: {
    [ConditionType in ConditionTypes]?: PricingApi.PriceImpact[]
  }
): MasterServicesPricingImpactsFormValues[] | undefined {
  if (isDef(priceImpacts)) {
    let priceImpactsValues = []

    if (priceImpacts['TIME']) {
      priceImpactsValues.push({
        isActive: true,
        type: ConditionTypes.TIME,
        conditions: priceImpacts['TIME'].map(impact => ({
          days: impact.condition.days,
          timeStart: impact.condition.timeStart,
          timeEnd: impact.condition.timeEnd,
          impactDirection: impact.impactDirection,
          impactType: impact.impactType,
          value: impact.value,
          nameInReceipt: impact.nameInReceipt,
        })),
      })
    }
    if (priceImpacts['GRADE']) {
      priceImpactsValues.push({
        isActive: true,
        type: ConditionTypes.GRADE,
        conditions: priceImpacts['GRADE'].map(impact => {
          const positionWithGrade = positions?.find(positions =>
            positions.grades.find(grade => grade.id === impact.condition.gradeId)
          )
          const positionWithoutGrade = positions?.find(position => position.id === impact.condition.gradeId)
          return {
            positionId: positionWithGrade ? positionWithGrade.id : positionWithoutGrade?.id,
            gradeId: positionWithGrade ? impact.condition.gradeId : undefined,
            impactDirection: impact.impactDirection,
            impactType: impact.impactType,
            value: impact.value,
            nameInReceipt: impact.nameInReceipt,
          }
        }),
      })
    }
    if (priceImpacts['DURATION']) {
      priceImpactsValues.push({
        isActive: true,
        type: ConditionTypes.DURATION,
        conditions: priceImpacts['DURATION'].map(impact => ({
          durationMinutes: impact.condition.durationMinutes,
          impactDirection: impact.impactDirection,
          impactType: impact.impactType,
          value: impact.value,
          nameInReceipt: impact.nameInReceipt,
        })),
      })
    }
    if (priceImpacts['WITH_TRAINER']) {
      priceImpactsValues.push({
        isActive: true,
        type: ConditionTypes.WITH_TRAINER,
        conditions: priceImpacts['WITH_TRAINER'].map(impact => ({
          impactDirection: impact.impactDirection,
          impactType: impact.impactType,
          value: impact.value,
          nameInReceipt: impact.nameInReceipt,
        })),
      })
    }
    return priceImpactsValues
  }
}

function genPricingBasePriceFormValues(
  positions: Nullable<EmployeesPositionsApi.EmployeePosition[]>,
  basePrices?: PricingApi.BasePrices[]
): MasterServicesPricingBasePriceFormValues[] | undefined {
  if (isDefAndNotEmpty(basePrices)) {
    let basePricesValues: MasterServicesPricingBasePriceFormValues[] = []

    basePrices.forEach(price => {
      if (price.condition.type === ConditionTypes.TIME) {
        basePricesValues.push({
          value: price.valueFrom,
          days: price.condition.days,
          timeStart: price.condition.timeStart,
          timeEnd: price.condition.timeEnd,
          nameInReceipt: price.nameInReceipt,
        })
      }
      if (price.condition.type === ConditionTypes.DURATION) {
        basePricesValues.push({
          value: price.valueFrom,
          durationMinutes: price.condition.durationMinutes,
          nameInReceipt: price.nameInReceipt,
        })
      }
      if (price.condition.type === ConditionTypes.GRADE) {
        const positionWithGrade = positions?.find(positions =>
          positions.grades.find(grade => grade.id === price.condition.gradeId)
        )
        const positionWithoutGrade = positions?.find(position => position.id === price.condition.gradeId)
        basePricesValues.push({
          value: price.valueFrom,
          positionId: positionWithGrade ? positionWithGrade.id : positionWithoutGrade?.id,
          gradeId: positionWithGrade ? price.condition.gradeId : undefined,
          nameInReceipt: price.nameInReceipt,
        })
      }
    })

    return basePricesValues
  }
}

export function genPricingFormValues(
  pricing: Nullable<PricingApi.Pricing>,
  positions: Nullable<EmployeesPositionsApi.EmployeePosition[]>
): Nullable<MasterServicesPricingFormValues> {
  if (isDef(pricing)) {
    const { id, name, holidayAsWeekend, basePrices, priceImpacts } = pricing
    const basePricesValues = genPricingBasePriceFormValues(positions, basePrices)

    const pricingValues: MasterServicesPricingFormValues = {
      id: id,
      name: name,
      holidayAsWeekend: holidayAsWeekend,
      priceType: basePrices[0]?.condition?.type ?? ConditionTypes.NONE,
      priceImpacts: genPricingImpactsFormValues(positions, priceImpacts),
    }

    if (basePrices[0]?.condition?.type === ConditionTypes.NONE) {
      pricingValues.value = basePrices[0]?.valueFrom ?? 0
      pricingValues.nameInReceipt = basePrices[0]?.nameInReceipt
      return pricingValues
    } else {
      return { ...pricingValues, basePrice: basePricesValues }
    }
  }
  return null
}
