import { Input } from 'antd'

import { useCounterpartiesSearch } from './counteraprties-search.hook'

interface CounterpartiesSearchContainerProps {
  className?: string
}

export const CounterpartiesSearchContainer = ({ className }: CounterpartiesSearchContainerProps) => {
  const { searchValue, onSearchHandler, isLoading } = useCounterpartiesSearch()

  return (
    <Input.Search
      style={{ width: '300px' }}
      className={className}
      allowClear
      loading={isLoading}
      value={searchValue}
      onChange={e => onSearchHandler(e.target.value)}
      placeholder={'Быстрый поиск...'}
    />
  )
}
