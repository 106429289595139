import dayjs from 'dayjs'

import { Days as DaysApi } from '../api/types/api.types'
import { ExercisesTimetableApi } from '../api/types/exercises-timetable-api.types'
import { StudiosApi } from '../api/types/studios-api.types'
import { ScheduleEditExercisesFormValues } from '../components/schedule/schedule-edit-exercises-form/schedule-edit-exercises-form.types'
import { ScheduleExercisesFilter } from '../components/schedule/schedule-exercises-filters/schedule-exercises-filters.types'
import { ScheduleExercise } from '../components/schedule/schedule-exercises-table/schedule-exercises-table.types'
import { ScheduleFormSlotsMap, ScheduleFormValuesDTO } from '../components/schedule/schedule-form/schedule-form.types'
import { ScheduleListDataItem } from '../components/schedule/schedule-list/schedule-list.types'
import {
  ScheduleModalConflict,
  ScheduleModalConflictItem,
} from '../components/schedule/schedule-modal-conflicts/schedule-modal-conflicts-exercise/schedule-modal-conflicts-exercise.types'
import { ScheduleOverview } from '../components/schedule/schedule-overview/schedule-overview.types'
import { ScheduleAddSlotsFormValues, ScheduleSlot } from '../components/schedule/schedule-slots/schedule-slots.types'
import { ScheduleTableDataItem } from '../components/schedule/schedule-table/schedule-table.types'
import { formatDate, formatTimeDifference } from '../format/date.format'
import { DEFAULT_EMPTY_SYMBOL } from '../format/text.format'
import { WebsocketTimetableMessage } from '../store/common/websocket/websocket-timetable/websocket-timetable.types'
import { Days } from '../types/days.types'
import { isDef, isDefAndNotEmpty, Nullable } from '../types/lang.types'
import { validateStrEnumValue } from '../utils/enum.utils'
import { mapDictionaryItemsListToEntityItemsList, mapDictionaryItemToEntityItem } from './api.mapping'

export function genExercisesTimetableDTO(
  values: Nullable<ScheduleFormValuesDTO>,
  longTerm?: boolean
): Nullable<ExercisesTimetableApi.ExercisesTimetableDTO> {
  if (isDef(values)) {
    const { date, bookingClients } = values
    const [dateFrom, dateTo] = date

    const directionField = values.direction && !values.subService ? values.direction : values.subService
    if (isDef(dateFrom) && isDef(dateTo)) {
      return {
        comment: values.comment,
        bookingClients,
        forceCancel: isDefAndNotEmpty(bookingClients),
        bookClient: isDefAndNotEmpty(bookingClients),
        direction: directionField !== undefined ? directionField : 0,
        type: values.type,
        timeslots: genExercisesTimetableSlotsMapDTO(values.slots),
        dateFrom,
        dateTo,
        longTerm: !!longTerm,
      }
    }
  }

  return null
}

function genExercisesTimetableSlotsMapDTO(
  slots: ScheduleFormSlotsMap
): ExercisesTimetableApi.ExercisesTimetableSlotsMapDTO {
  return Object.keys(slots).reduce<ExercisesTimetableApi.ExercisesTimetableSlotsMapDTO>(
    (acc: ExercisesTimetableApi.ExercisesTimetableSlotsMapDTO, key: string) => {
      const day = validateStrEnumValue<DaysApi>(DaysApi, key)

      if (isDef(day)) {
        const values = slots[day]?.reduce<ExercisesTimetableApi.ExercisesTimetableSlotDTO[]>((acc, slot) => {
          const exercisesTimetableSlotDTO = genExercisesTimetableSlotDTO(slot)

          if (isDef(exercisesTimetableSlotDTO)) {
            acc.push(exercisesTimetableSlotDTO)
          }

          return acc
        }, [])

        if (isDefAndNotEmpty(values)) {
          acc[day] = values
        }
      }

      return acc
    },
    {}
  )
}

function genExercisesTimetableSlotDTO(slot: ScheduleSlot): Nullable<ExercisesTimetableApi.ExercisesTimetableSlotDTO> {
  const { time, room, trainers, maxClientsCount } = slot
  const [timeFrom, timeTo] = time

  if (isDef(timeFrom) && isDef(timeTo) && isDef(room)) {
    return {
      timeFrom,
      timeTo,
      room,
      maxClientsCount: maxClientsCount || 1,
      trainers: trainers || [],
    }
  }

  return null
}

export function mapExercisesTimetableToScheduleTableDataItems(
  exercisesTimetable: Nullable<ExercisesTimetableApi.ExercisesTimetable[]>
): ScheduleTableDataItem[] | undefined {
  if (isDefAndNotEmpty(exercisesTimetable)) {
    return exercisesTimetable.reduce<ScheduleTableDataItem[]>((acc, exercisesTimetableItem) => {
      const { id, direction, type, rooms, timeslots, dateFrom, dateTo, trainers, format } = exercisesTimetableItem

      if (
        isDef(direction) &&
        isDef(type) &&
        isDef(timeslots) &&
        isDef(dateFrom) &&
        isDef(dateTo)
        // isDefAndNotEmpty(trainers)
      ) {
        const directionEntity = mapDictionaryItemToEntityItem(direction)
        const typeEntity = mapDictionaryItemToEntityItem(type)
        const trainersEntities = mapDictionaryItemsListToEntityItemsList(trainers)
        const days = genDaysFromExercisesTimetableTimeslotsMap(timeslots)

        if (
          isDef(directionEntity) &&
          isDef(typeEntity) &&
          isDefAndNotEmpty(days) &&
          isDefAndNotEmpty(rooms)
          // isDefAndNotEmpty(trainersEntities)
        ) {
          const scheduleTableDataItem: ScheduleTableDataItem = {
            id,
            direction: directionEntity,
            type: typeEntity,
            trainers: trainersEntities ? trainersEntities : [],
            studioRooms: rooms,
            days,
            dateFrom,
            dateTo,
            format,
          }

          acc.push(scheduleTableDataItem)
        }
      }

      return acc
    }, [])
  }
}

export function mapExercisesTimetableToScheduleListDataItems(
  exercisesTimetable: Nullable<ExercisesTimetableApi.ExercisesTimetable[]>
): ScheduleListDataItem[] | undefined {
  if (isDefAndNotEmpty(exercisesTimetable)) {
    return exercisesTimetable.reduce<ScheduleListDataItem[]>((acc, exercisesTimetableItem) => {
      const {
        id,
        direction,
        rooms,
        dateFrom,
        dateTo,
        trainers,
        longTermClient,
        format,
        totalExercises,
        futureExercisesCount,
      } = exercisesTimetableItem

      if (isDef(direction) && isDef(dateFrom) && isDef(dateTo) && isDef(longTermClient)) {
        const directionEntity = mapDictionaryItemToEntityItem(direction)
        const trainersEntities = mapDictionaryItemsListToEntityItemsList(trainers)
        const client = {
          id: longTermClient.client.id,
          phone: longTermClient.client.phone,
          name:
            longTermClient.client.firstName || longTermClient.client.lastName
              ? `${longTermClient.client.firstName ?? ''} ${longTermClient.client.lastName ?? ''}`
              : DEFAULT_EMPTY_SYMBOL,
          category: longTermClient.client.category.name,
        }

        if (isDef(directionEntity) && isDefAndNotEmpty(rooms)) {
          const scheduleTableDataItem: ScheduleListDataItem = {
            id,
            direction: directionEntity,
            trainers: trainersEntities ? trainersEntities : [],
            studioRooms: rooms,
            client,
            dateFrom,
            dateTo,
            format,
            totalExercises,
            futureExercises: futureExercisesCount,
            payedBookingsCount: longTermClient.payedBookingsCount,
          }

          acc.push(scheduleTableDataItem)
        }
      }

      return acc
    }, [])
  }
}

function genDaysFromExercisesTimetableTimeslotsMap(
  exercisesTimetableSlotsMap: Nullable<ExercisesTimetableApi.ExercisesTimetableSlotsMap>
): Nullable<Days[]> {
  if (isDef(exercisesTimetableSlotsMap)) {
    return Object.entries(exercisesTimetableSlotsMap).reduce<Days[]>((acc, [key, slots]) => {
      const day = validateStrEnumValue<Days>(Days, key)

      if (isDef(day) && isDefAndNotEmpty(slots)) {
        acc.push(day)
      }

      return acc
    }, [])
  }

  return null
}

export function genScheduleOverview(
  timetable: Nullable<ExercisesTimetableApi.ExercisesTimetable>
): Nullable<ScheduleOverview> {
  if (isDef(timetable)) {
    const { direction, type, dateTo, dateFrom, totalExercises, comment } = timetable

    return {
      direction,
      type,
      dateFrom,
      dateTo,
      totalExercises,
      comment,
    }
  }

  return null
}

export function genLongtermScheduleOverview(
  timetable: Nullable<ExercisesTimetableApi.ExercisesTimetable>
): Nullable<ScheduleOverview> {
  if (isDef(timetable)) {
    const { direction, type, dateTo, dateFrom, longTermClient, totalExercises, comment } = timetable

    if (isDef(longTermClient)) {
      const client = {
        id: longTermClient.client.id,
        phone: longTermClient.client.phone,
        name:
          longTermClient.client.firstName || longTermClient.client.lastName
            ? `${longTermClient.client.firstName ?? ''} ${longTermClient.client.lastName ?? ''}`
            : DEFAULT_EMPTY_SYMBOL,
        category: longTermClient.client.category.name,
      }

      return {
        client,
        direction,
        type,
        dateFrom,
        dateTo,
        totalExercises,
        payedBookingsCount: longTermClient.payedBookingsCount,
        comment,
      }
    }
  }

  return null
}

export function genScheduleExercises(
  exercises: Nullable<ExercisesTimetableApi.ExercisesTimetableExercise[]>
): Nullable<ScheduleExercise[]> {
  if (isDefAndNotEmpty(exercises)) {
    return exercises.map(exercise => ({
      date: formatDate(exercise.timeFrom, 'DD MMM'),
      timeFrom: formatDate(exercise.timeFrom, 'HH:mm'),
      id: exercise.id,
      duration: formatTimeDifference(exercise.timeFrom, exercise.timeTo),
      studio: exercise.studio,
      room: exercise.room.name,
      trainers: exercise.trainers?.map(trainer => trainer.name),
      paymentType: exercise.masterServiceBooking?.paymentType,
      canceled: exercise.canceled,
    }))
  }

  return null
}

export function genScheduleEditExercisesFormValues(
  exercisesFilter: ScheduleExercisesFilter,
  studios?: Nullable<StudiosApi.Studio[]>,
  studioOffset?: number
): ScheduleEditExercisesFormValues {
  const { trainerIds, dayOfWeek, timeFrom, timeTo, roomId } = exercisesFilter

  const formatTime = (time: string) => {
    const date = dayjs.utc(time, 'HH:mm:ss.SSSZ')
    const adjustedDate = date.add(studioOffset || 0, 'hour')

    return adjustedDate.format('HH:mm')
  }

  const values = {
    ...(isDefAndNotEmpty(roomId) && {
      studio: {
        oldValue: studios?.find(studio => studio.rooms?.find(room => room.id === roomId[0]))?.id,
        newValue: undefined,
      },
      room: {
        oldValue: roomId[0],
        newValue: undefined,
      },
    }),
    ...(isDefAndNotEmpty(trainerIds) && {
      trainers: {
        oldValue: trainerIds?.map(trainer => trainer),
        newValue: undefined,
      },
    }),
    ...(isDefAndNotEmpty(dayOfWeek) && {
      dayOfWeek: {
        oldValue: dayOfWeek[0],
      },
    }),
    ...(isDef(timeFrom) && {
      timeFrom: {
        oldValue: formatTime(timeFrom),
      },
    }),
    ...(isDef(timeTo) && {
      timeTo: {
        oldValue: formatTime(timeTo),
      },
    }),
  }

  return values
}

export function genScheduleExercisesEditDTO(
  formValues: ScheduleEditExercisesFormValues,
  exercisesFilter: ScheduleExercisesFilter,
  studioOffset?: number
): ExercisesTimetableApi.ExercisesTimetableEditDTO {
  const dto: ExercisesTimetableApi.ExercisesTimetableEditDTO = {
    filter: {},
    update: {},
  }

  const timeZone = studioOffset ?? 0

  // Fill the filter with values from oldValue
  if (formValues.dayOfWeek?.oldValue) {
    dto.filter.dayOfWeek = formValues.dayOfWeek.oldValue
  }

  if (formValues.timeFrom?.oldValue) {
    const time = dayjs(formValues.timeFrom.oldValue, 'HH:mm').subtract(timeZone, 'hour').format('HH:mm:ss.SSS[Z]')
    dto.filter.timeFrom = time
  }

  if (formValues.timeTo?.oldValue) {
    const time = dayjs(formValues.timeTo.oldValue, 'HH:mm').subtract(timeZone, 'hour').format('HH:mm:ss.SSS[Z]')
    dto.filter.timeTo = time
  }

  if (formValues.room?.oldValue) {
    dto.filter.roomId = formValues.room.oldValue
  }

  if (isDefAndNotEmpty(formValues.trainers?.oldValue)) {
    const noTrainer = formValues.trainers?.oldValue.find(trainer => trainer === 'noTrainer')
    dto.filter.trainerIds = !noTrainer
      ? formValues.trainers?.oldValue?.filter((value): value is string => value !== undefined)
      : []
  }

  // Fill the filter with values from exercisesFilter
  if (isDefAndNotEmpty(exercisesFilter.timeScope)) {
    dto.filter.timeScope = exercisesFilter.timeScope[0]
  }

  if (isDefAndNotEmpty(exercisesFilter.bookingPaymentStatus)) {
    dto.filter.bookingPaymentStatus = exercisesFilter.bookingPaymentStatus[0]
  }

  if (isDefAndNotEmpty(exercisesFilter.paymentType)) {
    dto.filter.paymentType = exercisesFilter.paymentType[0]
  }

  if (isDefAndNotEmpty(exercisesFilter.bookingStatuses)) {
    dto.filter.bookingStatuses = exercisesFilter.bookingStatuses
  }

  if (exercisesFilter.dateFrom) {
    dto.filter.dateFrom = exercisesFilter.dateFrom
  }

  if (exercisesFilter.dateTo) {
    dto.filter.dateTo = exercisesFilter.dateTo
  }

  // Extract values from formValues and fill the DTO
  if (
    formValues.dayOfWeek?.newValue &&
    formValues.dayOfWeek?.oldValue &&
    formValues.dayOfWeek?.newValue !== formValues.dayOfWeek?.oldValue
  ) {
    dto.update.dayDelta = {
      dayOfWeek: {
        old: formValues.dayOfWeek.oldValue,
        new: formValues.dayOfWeek.newValue,
      },
    }
  }

  if (
    formValues.timeFrom?.newValue &&
    formValues.timeFrom?.oldValue &&
    formValues.timeFrom.oldValue !== formValues.timeFrom.newValue
  ) {
    const oldTime = dayjs(formValues.timeFrom.oldValue, 'HH:mm').subtract(timeZone, 'hour').format('HH:mm:ss.SSS[Z]')
    const newTime = dayjs(formValues.timeFrom.newValue, 'HH:mm').subtract(timeZone, 'hour').format('HH:mm:ss.SSS[Z]')

    dto.update.timeFrom = {
      old: oldTime,
      new: newTime,
    }
  }

  if (
    formValues.timeTo?.newValue &&
    formValues.timeTo?.oldValue &&
    formValues.timeTo.oldValue !== formValues.timeTo.newValue
  ) {
    const oldTime = dayjs(formValues.timeTo.oldValue, 'HH:mm').subtract(timeZone, 'hour').format('HH:mm:ss.SSS[Z]')
    const newTime = dayjs(formValues.timeTo.newValue, 'HH:mm').subtract(timeZone, 'hour').format('HH:mm:ss.SSS[Z]')

    dto.update.timeTo = {
      old: oldTime,
      new: newTime,
    }
  }

  if (formValues.room?.newValue && formValues.room?.oldValue && formValues.room.oldValue !== formValues.room.newValue) {
    dto.update.roomId = {
      old: formValues.room.oldValue,
      new: formValues.room.newValue,
    }
  }

  if (formValues.trainers?.oldValue && formValues.trainers?.newValue) {
    dto.update.trainerIds = {
      old: !formValues.trainers.oldValue.find(trainer => trainer === 'noTrainer')
        ? formValues.trainers.oldValue.filter((value): value is string => value !== undefined)
        : [],
      new: !formValues.trainers.newValue.find(trainer => trainer === 'noTrainer')
        ? formValues.trainers.newValue.filter((value): value is string => value !== undefined)
        : [],
    }
  }

  return dto
}

export function genScheduleExercisesAddDTO(
  formValues: ScheduleAddSlotsFormValues
): ExercisesTimetableApi.ExercisesTimetableAddDTO {
  const { slots, date } = formValues

  const dateFrom = date[0] ? date[0].format('YYYY-MM-DD') : ''
  const dateTo = date[1] ? date[1].format('YYYY-MM-DD') : ''

  const timeslots: ExercisesTimetableApi.ExercisesTimetableSlotsMapDTO = {}

  for (const day in slots) {
    if (slots.hasOwnProperty(day)) {
      const dayKey = day as Days

      const scheduleSlots = slots[dayKey]

      const transformedSlots = scheduleSlots
        ?.map(slot => {
          const timeFrom = slot.time?.[0]
          const timeTo = slot.time?.[1]

          if (isDef(timeFrom) && isDef(timeTo)) {
            return {
              timeFrom,
              timeTo,
              room: slot.room,
              maxClientsCount: slot.maxClientsCount,
              trainers: slot.trainers,
            }
          }
          return undefined
        })
        .filter(Boolean) as ExercisesTimetableApi.ExercisesTimetableSlotDTO[]

      if (isDefAndNotEmpty(transformedSlots)) {
        timeslots[dayKey] = transformedSlots
      }
    }
  }

  return {
    dateFrom,
    dateTo,
    timeslots,
  }
}

export function getTotalExercises(
  dateFrom: string,
  dateTo: string,
  timeslots: ExercisesTimetableApi.ExercisesTimetableSlotsMapDTO
): number {
  const startDate = dayjs(dateFrom)
  const endDate = dayjs(dateTo)
  let totalExercises = 0

  const getDayOfWeek = (date: dayjs.Dayjs): Days => {
    const daysOfWeek = [
      Days.SUNDAY,
      Days.MONDAY,
      Days.TUESDAY,
      Days.WEDNESDAY,
      Days.THURSDAY,
      Days.FRIDAY,
      Days.SATURDAY,
    ]
    return daysOfWeek[date.day()]
  }

  for (
    let currentDate = startDate;
    currentDate.isBefore(endDate) || currentDate.isSame(endDate, 'day');
    currentDate = currentDate.add(1, 'day')
  ) {
    const dayOfWeek = getDayOfWeek(currentDate)
    const slots = timeslots[dayOfWeek]
    if (slots) {
      totalExercises += slots.length
    }
  }

  return totalExercises
}

export function mapWebsocketTimetableMessagesToConflicts(
  messages: WebsocketTimetableMessage[]
): ScheduleModalConflictItem[] {
  return messages
    .filter(message => !message.exercise.isCanceled && (message.error !== null || message.conflicts !== null))
    .map(message => {
      const conflicts: ScheduleModalConflict = {}

      if (isDefAndNotEmpty(message.conflicts)) {
        const trainers = message.conflicts.filter(conflict => isDefAndNotEmpty(conflict.trainers))
        const rooms = message.conflicts.filter(conflict => isDef(conflict.room))

        if (isDefAndNotEmpty(trainers)) {
          conflicts.trainers = trainers.map(conflict => ({
            conflictingExerciseId: conflict.conflictingExerciseId,
            timeFrom: conflict.timeFrom,
            timeTo: conflict.timeTo,
            room: conflict.room,
            trainers: conflict.trainers?.filter(trainerInConflict =>
              message.exercise.trainers?.some(trainerInExercise => trainerInExercise.id === trainerInConflict.id)
            ),
          }))
        }

        if (isDefAndNotEmpty(rooms)) {
          conflicts.rooms = rooms.map(conflict => ({
            conflictingExerciseId: conflict.conflictingExerciseId,
            timeFrom: conflict.timeFrom,
            timeTo: conflict.timeTo,
            room: conflict.room,
            trainers: conflict.trainers,
          }))
        }
      }

      return {
        action: message.action,
        exercise: message.exercise,
        conflicts: Object.keys(conflicts).length > 0 ? conflicts : undefined,
        error: message.error,
      }
    })
}

function genScheduleExercisesFilter(exercisesFilter: ScheduleExercisesFilter) {
  return {
    timeFrom: exercisesFilter.timeFrom,
    timeTo: exercisesFilter.timeTo,
    dayOfWeek: exercisesFilter.dayOfWeek ? exercisesFilter.dayOfWeek[0] : undefined,
    dateFrom: exercisesFilter.dateFrom,
    dateTo: exercisesFilter.dateTo,
    roomId: exercisesFilter.roomId ? exercisesFilter.roomId[0] : undefined,
    trainerIds: isDefAndNotEmpty(exercisesFilter.trainerIds)
      ? exercisesFilter.trainerIds[0] === 'noTrainer'
        ? []
        : exercisesFilter.trainerIds
      : undefined,
    timeScope: exercisesFilter.timeScope ? exercisesFilter.timeScope[0] : undefined,
    bookingPaymentStatus: exercisesFilter.bookingPaymentStatus ? exercisesFilter.bookingPaymentStatus[0] : undefined,
    paymentType: exercisesFilter.paymentType ? exercisesFilter.paymentType[0] : undefined,
    bookingStatuses: isDefAndNotEmpty(exercisesFilter.bookingStatuses) ? exercisesFilter.bookingStatuses : undefined,
  }
}

export function genScheduleExercisesPayDTO(
  phone: string,
  exercisesFilter: ScheduleExercisesFilter,
  selectedPaymentType: ExercisesTimetableApi.ExercisesTimetablePaymentType
): ExercisesTimetableApi.ExercisesTimetablePayDTO {
  const filter = genScheduleExercisesFilter(exercisesFilter)

  return {
    phone,
    filter,
    paymentType: selectedPaymentType.paymentType,
    subscriptionId: selectedPaymentType.clientSubscriptionId,
  }
}

export function genScheduleExercisesEditCommentDTO(
  exercisesFilter: ScheduleExercisesFilter,
  comment: string
): ExercisesTimetableApi.ExercisesTimetableEditDTO {
  const filter = genScheduleExercisesFilter(exercisesFilter)

  return {
    filter,
    update: {
      comment: {
        old: '',
        new: comment,
      },
    },
  }
}
