import dayjs from 'dayjs'
import { genDefaultDayjsFormat } from '@format/date.format'

import { isDef, isDefAndNotEmpty, Nullable } from '../types/lang.types'
import { ContractsApi } from '../api/types/contracts-api.types'

export function genCounterpartiesFormValues(counterparty: Nullable<ContractsApi.CounterpartiesDTO>): Nullable<any> {
  if (isDef(counterparty)) {
    return {
      companyName: counterparty.companyName,
      displayName: counterparty.companyName,
      ogrn: counterparty.ogrn,
      inn: counterparty.inn,
      kpp: counterparty.kpp,
      type: counterparty.type,
      passportIssueDate: isDef(counterparty.passportIssueDate)
        ? dayjs(counterparty.passportIssueDate, genDefaultDayjsFormat())
        : undefined,
      passportIssuer: counterparty.passportIssuer || null,
      passportNumber: counterparty.passportNumber || null,
      passportSeries: counterparty.passportSeries || null,
      birthDate: isDef(counterparty.birthDate) ? dayjs(counterparty.birthDate, genDefaultDayjsFormat()) : undefined,
      snoType: counterparty.snoType,
      address: counterparty.address,
      directorName: counterparty.directorName,
      phoneNumber: counterparty.phoneNumber,
      email: counterparty.email,
      bankName: counterparty.bankName,
      bic: counterparty.bic,
      bankAccount: counterparty.bankAccount,
      correspondentAccount: counterparty.correspondentAccount,
    }
  }

  return null
}

export function genCounterpartyTotalTurnover(counterparty: Nullable<ContractsApi.Counterparties>) {
  return Number(counterparty?.activeContracts?.reduce((sum, contract) => sum + (contract.lifetimeTurnover || 0), 0))
}

export function genCounterpartyTotalBalance(
  counterparty: Nullable<ContractsApi.Counterparties>,
  totalTurnover: number = 0
) {
  const totalAmount =
    counterparty?.activeContracts?.reduce((sum, contract) => sum + (contract.maxTotalAmount || 0), 0) || 0
  return totalAmount - totalTurnover
}

export function genCounterpartyByIdDebitAndCredit(counterparty: Nullable<ContractsApi.Counterparties>): {
  debit: number
  credit: number
} {
  if (!isDefAndNotEmpty(counterparty?.activeContracts)) {
    return { debit: 0, credit: 0 }
  }

  let debit = 0
  let credit = 0

  counterparty?.activeContracts.forEach(contract => {
    if (['ACTIVE', 'AWAITS_PAYMENT'].includes(contract.status)) {
      const amount = contract.maxPeriodAmount || 0
      const turnover = contract.currentTurnover || 0
      const creditBalance = turnover
      const debitBalance = amount - turnover

      if (contract.type === 'POSTPAY') {
        credit += creditBalance
      } else if (contract.type === 'PREPAY') {
        debit += debitBalance
      }
    }
  })

  return {
    debit,
    credit,
  }
}
