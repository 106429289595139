import React from 'react'
import { Button, Flex, notification, Select, Typography } from 'antd'
import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons'
import { clsx } from 'clsx'
import { DefaultOptionType } from 'antd/lib/select'
import { useParams } from 'react-router-dom'

import { formatContractBalance } from '../../../../../format/contract.format'
import { ContractsPageInfoParams } from '../../../contracts-info-page.types'
import { useContractInfoContractCard } from './contracts-info-contract-card.hook'
import { ContractsApi } from '../../../../../api/types/contracts-api.types'
import { formatDateToHumanize } from '../../../../../format/date.format'
import style from './contracts-info-contract-card.module.scss'
import { formatRubleCurrency } from '../../../../../format/number.format'

interface ContractsInfoContractCardProps {
  contract: ContractsApi.Contracts
  managers?: DefaultOptionType[]
  onChangeManagers?: (managerId: ContractsApi.ManagerId, contractId: string) => void
  onPaidContract?: () => void
}

export const ContractsInfoContractCard = ({ contract, managers, onChangeManagers }: ContractsInfoContractCardProps) => {
  const { id } = useParams<ContractsPageInfoParams>()
  const { onPaidContractHandler, onDeleteContractHandler } = useContractInfoContractCard(
    contract.id,
    id,
    contract.maxTotalAmount
  )
  const { balance } = formatContractBalance(
    contract.maxTotalAmount,
    contract?.type,
    contract.lifetimeTurnover,
    contract.status
  )
  const turnover = formatRubleCurrency(contract.lifetimeTurnover)

  function onChangeManagersHandler(managerId: string) {
    if (managerId && contract) {
      onChangeManagers?.({ managerId }, contract.id)
    }
  }

  function handleClickPayable() {
    notification.error({
      message: 'Будет доступно позже',
    })
  }

  return (
    <Flex
      vertical
      gap={'large'}
      className={clsx(style.cardContainer, {
        [style.containerPostPay]: contract.status === 'AWAITS_PAYMENT' || contract.status === 'PAYMENT_DUE',
      })}
      justify={'center'}
    >
      <Flex vertical>
        <Typography.Text>До {formatDateToHumanize(contract.validUntil)}</Typography.Text>
        <Flex justify={'space-between'} style={{ height: '64px' }}>
          <Flex vertical>
            <Typography.Title level={5} className={style.cardTitle}>
              Договор {contract.name}
            </Typography.Title>
            <Typography.Text type={'secondary'}>
              {contract.type === 'POSTPAY' ? 'Постоплата' : `Предоплата`}
            </Typography.Text>
          </Flex>
          <Flex gap={'small'}>
            {contract.link && (
              <a href={contract.link} target="_blank" download rel="noreferrer">
                <Button size="small" icon={<DownloadOutlined />} />
              </a>
            )}
            <Button size="small" icon={<DeleteOutlined />} onClick={onDeleteContractHandler} />
          </Flex>
        </Flex>
      </Flex>
      <Flex className={style.contractBalanceBadge} gap={'small'} justify={'space-around'}>
        <div
          className={clsx(style.balanceBadge, {
            [style.balanceBadgeNotPay]: contract.status === 'AWAITS_PAYMENT' || contract.status === 'PAYMENT_DUE',
          })}
        >
          <span className={style.badgeText}>Баланс</span>
          <span className={style.badgeNumber}>{balance}</span>
        </div>
        <div
          className={clsx(style.balanceBadge, {
            [style.balanceBadgeNotPay]: contract.status === 'AWAITS_PAYMENT' || contract.status === 'PAYMENT_DUE',
          })}
        >
          <span className={style.badgeText}>Лимит</span>
          <span className={style.badgeNumber}>
            {contract.type === 'POSTPAY'
              ? `-${formatRubleCurrency(contract.maxTotalAmount)}`
              : `${formatRubleCurrency(contract.maxTotalAmount)}`}
          </span>
        </div>
        <div
          className={clsx(style.balanceBadge, {
            [style.balanceBadgeNotPay]: contract.status === 'AWAITS_PAYMENT' || contract.status === 'PAYMENT_DUE',
          })}
        >
          <span className={style.badgeText}>Оборот</span>
          <span className={style.badgeNumber}>{turnover}</span>
        </div>
      </Flex>
      <Flex justify={'space-evenly'} style={{ gap: '16px', marginTop: '8px' }}>
        <Button style={{ width: '100%' }} onClick={handleClickPayable}>
          Счет к оплате
        </Button>
        {(contract.status === 'AWAITS_PAYMENT' || contract.status === 'PAYMENT_DUE') && (
          <Button style={{ width: '100%' }} type={'primary'} onClick={onPaidContractHandler}>
            Оплатить
          </Button>
        )}
      </Flex>
      <Select
        defaultValue={`Менеджер: ${contract.manager.name}`}
        className={style.managerSelect}
        onChange={onChangeManagersHandler}
        options={managers}
      />
    </Flex>
  )
}
