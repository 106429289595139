import { AxiosResponse } from 'axios'

import { Nullable } from '../../types/lang.types'
import { HttpConnector } from '../connectors/http.connector'
import { Pagination, PaginationParamsDTO } from '../types/api.types'
import { PricingApi } from '../types/pricing.types'

export class PricingService {
  constructor(private readonly httpConnector: HttpConnector) {}

  public fetchAll = (
    params: Nullable<Partial<PaginationParamsDTO>>
  ): Promise<AxiosResponse<Pagination<PricingApi.Pricing>>> => {
    return this.httpConnector.get<Pagination<PricingApi.Pricing>>('/pricing', {
      params,
    })
  }

  public fetchById = (id: string): Promise<AxiosResponse<PricingApi.Pricing>> => {
    return this.httpConnector.get<PricingApi.Pricing>(`/pricing/${id}`)
  }

  public fetchBySubserviceId = (id: string): Promise<AxiosResponse<PricingApi.SubservicePricing>> => {
    return this.httpConnector.get<PricingApi.SubservicePricing>(`/pricing/subservice/${id}`)
  }

  public create = (data: PricingApi.PricingDTO): Promise<AxiosResponse<PricingApi.Pricing>> => {
    return this.httpConnector.post<PricingApi.Pricing>('/pricing', data)
  }

  public update = (id: string, data: PricingApi.PricingDTO): Promise<AxiosResponse<PricingApi.Pricing>> => {
    return this.httpConnector.put<PricingApi.Pricing>(`/pricing/${id}`, data)
  }

  public remove = (id: string): Promise<void> => {
    return this.httpConnector.delete(`/pricing/${id}`)
  }

  public updateStartDate = (
    pricingId: string,
    subServiceId: string,
    data: { fromDate: string }
  ): Promise<AxiosResponse<PricingApi.Pricing>> => {
    return this.httpConnector.put<PricingApi.Pricing>(`/pricing/${pricingId}/subservice/${subServiceId}/start`, data)
  }

  public subserviceConnection = (
    pricingId: string,
    subServiceId: string,
    data: PricingApi.PricingConnectedDTO
  ): Promise<AxiosResponse<PricingApi.Pricing>> => {
    return this.httpConnector.put<PricingApi.Pricing>(`/pricing/${pricingId}/subservice/${subServiceId}`, data)
  }

  public subserviceDisconnection = (
    pricingId: string,
    subServiceId: string
  ): Promise<AxiosResponse<PricingApi.Pricing>> => {
    return this.httpConnector.delete<PricingApi.Pricing>(`/pricing/${pricingId}/subservice/${subServiceId}`)
  }
}
