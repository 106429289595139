import { Select, Typography } from 'antd'
import { DefaultOptionType } from 'antd/es/select'

interface SelectFilteredOptionsProps {
  value: string[]
  placeholder: string
  groupLabel: string
  loading: boolean
  options?: DefaultOptionType[]
  dropdownClassName?: string
  showSearch?: boolean
  disableFilteredOptions?: boolean
  onChange: (value: string[]) => void
}

export const SelectFilteredOptions: React.FC<SelectFilteredOptionsProps> = ({
  value,
  placeholder,
  groupLabel,
  loading,
  options,
  dropdownClassName = 'customDropdown',
  showSearch = false,
  disableFilteredOptions = false,
  onChange,
}) => {
  return (
    <Select
      value={value}
      placeholder={placeholder}
      disabled={loading}
      loading={loading}
      dropdownClassName={dropdownClassName}
      mode="multiple"
      showSearch={showSearch}
      filterOption={(input, option) => {
        const label = option?.label ? String(option.label) : ''
        return label.toLowerCase().includes(input.toLowerCase())
      }}
      onChange={onChange}
    >
      {options
        ?.filter(option => !option.filteredOut)
        ?.map(option => (
          <Select.Option key={option.value} value={option.value} label={option.label}>
            <Typography style={{ color: 'black' }}>{option.label}</Typography>
          </Select.Option>
        ))}
      {options?.some(option => option.filteredOut) && (
        <Select.OptGroup label={groupLabel}>
          {options
            ?.filter(option => option.filteredOut)
            .map(option => (
              <Select.Option
                key={option.value}
                value={option.value}
                label={option.label}
                disabled={disableFilteredOptions}
              >
                <Typography style={{ color: 'grey' }}>{option.label}</Typography>
              </Select.Option>
            ))}
        </Select.OptGroup>
      )}
    </Select>
  )
}
