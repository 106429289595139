import { FranchisesForm } from '../../../components/franchises/franchises-form/franchises-form.component'
import { useFranchisesEditPageForm } from './franchises-edit-page-form.hook'

export const FranchisesEditPageForm = () => {
  const { form, isLoading, isUpdating, studiosOptions, customersOptions, onFinishHandler, terminals, tills } =
    useFranchisesEditPageForm()

  return (
    <FranchisesForm
      form={form}
      isLoading={isLoading}
      isUpdating={isUpdating}
      studiosOptions={studiosOptions}
      tills={tills}
      terminals={terminals}
      customersOptions={customersOptions}
      submitText="Обновить компанию"
      onFinish={onFinishHandler}
    />
  )
}
