import { UserOutlined } from '@ant-design/icons'
import { api } from '@api/api'
import { SelectRecordType } from '@components/ui/select-record-type/ui/select-record-type.component'
import { TimeRangePicker } from '@components/ui/time-range-picker/time-range-picker.component'
import { genPaymentTypesOptions } from '@utils/payment.utils'
import { Button, Flex, Form, InputNumber, Select, Switch, Tooltip, Typography } from 'antd'
import { DefaultOptionType } from 'antd/es/select'
import dayjs from 'dayjs'
import { FC, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { useTheme } from '../../../hooks/use-theme.hook'
import { isDef } from '../../../types/lang.types'
import { PaymentType } from '../../../types/payment.types'
import { ClientsDataFields } from '../../clients/clients-data-fields/clients-data-fields.component'
import { SelectFilteredOptions } from '../../ui/select-filtered-options/select-filtered-options.component'
import { useExercisesForm } from './exercises-form.hook'
import './exercises-form.styles.less'
import { ExercisesFormProps } from './exercises-form.types'
import { getAvailableRooms } from './exercises-form.utils'

export const ExercisesForm: FC<ExercisesFormProps> = ({
  form,
  loading,
  exercisesTypes,
  trainers,
  studioOffset,
  onSave,
  onCancel,
  onChangeTime,
  directionIsDisabled = false,
  exercisesTypeIsDisabled = false,
  currentRoom,
  roomsOptions,
  saveStatus = 'default',
  isFrozenTime = false,
  schedule,
}) => {
  // Custom hooks
  const { theme } = useTheme()
  const {
    exercisesTypesOptions,
    masterServicesOptions,
    subServicesOptions,
    trainersOptions,
    directionsOptions,
    directionValidationRules,
    masterServiceValidationRules,
    subServiceValidationRules,
    timeToValidationRules,
    maxClientsCountValidationRules,
    typeValidationRules,
    paymentTypeValidationRules,
    isLoadingClient,
    onChangeTimeHandler,
    onChangeTrainersHandler,
    onChangeRoomsHandler,
    onChangePaymentTypeHandler,
    onChangeDirectionHandler,
    onChangeExercisesTypeHandler,
    onChangeMaxClientsCountHandler,
    onChangeMasterServiceHandler,
    onChangeSubServicesHandler,
    onFinishHandler,
    isCreating,
    isShowGuestRecord,
    selectedTypeExercise,
    setIsShowGuestRecord,
    isBookSameTimeInOtherRooms,
    setIsBookSameTimeInOtherRooms,
    submitButtonRef,
    onBlurTimeHandler,
    setClientCurrentData,
    setIsLoadingClient,
  } = useExercisesForm({
    form,
    exercisesTypes,
    trainers,
    studioOffset,
    onSave,
    onChangeTime,
    isFrozenTime,
    schedule,
    roomsOptions,
  })

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const date = queryParams.get('date')

  const phoneWatcher = Form.useWatch('phone', form)
  const directionWatcher = Form.useWatch('direction', form)
  const paymentTypeWatcher = Form.useWatch('paymentType', form)
  const timeWatcher = Form.useWatch('time', form)
  const subServiceWatcher = Form.useWatch('subService', form)

  const toggleShowGuestRecord = () => {
    setIsShowGuestRecord(!isShowGuestRecord)
  }

  const toggleBookSameTimeInOtherRooms = () => {
    setIsBookSameTimeInOtherRooms(!isBookSameTimeInOtherRooms)
    form.resetFields(['rooms'])
  }

  // Clients search
  const [paymentTypesOptions, setPaymentTypesOptions] = useState<DefaultOptionType[]>([])
  const [hasFocusGuestsSearch, setHasFocusGuestsSearch] = useState(false)

  const [studiosRoomsOptions, setStudiosRoomsOptions] = useState<DefaultOptionType[] | undefined>(undefined)

  const isAvailablePaymentTypes = useMemo(() => {
    const values = form.getFieldsValue()
    return (
      isDef(values.phone) &&
      isDef(values.time) &&
      isDef(values.type) &&
      (isDef(values.direction) || isDef(values.subService))
    )
  }, [phoneWatcher, directionWatcher, paymentTypeWatcher, timeWatcher, subServiceWatcher])

  useEffect(() => {
    ;(async () => {
      const values = form.getFieldsValue()

      if (isAvailablePaymentTypes) {
        const currentDate = date || dayjs().format('YYYY-MM-DD')

        const response = await api.clientsBookingsService
          .fetchPaymentTypes({
            phone: values.phone?.replace(/\D/g, '') as string,
            directionId: (values?.direction ?? values?.subService) as number,
            typeId: values.type,
            timeFrom: dayjs(`${currentDate}T${values.time.start}`).toISOString(),
            timeTo: dayjs(`${currentDate}T${values.time.end}`).toISOString(),
            roomId: currentRoom?.id as string,
          })
          .catch(console.error)

        const safeTypes: PaymentType[] = [PaymentType.ON_PLACE]
        setPaymentTypesOptions(genPaymentTypesOptions(response?.data ?? safeTypes, true))
      }
    })()
  }, [phoneWatcher, directionWatcher, paymentTypeWatcher, timeWatcher])

  const toggleHasFocusGuestsSearch = () => {
    setHasFocusGuestsSearch(!hasFocusGuestsSearch)
  }

  useEffect(() => {
    form.setFieldsValue({
      maxClientsCount: currentRoom?.totalCapacity ?? 1,
    })
  }, [currentRoom])

  // Buttons
  const [isDisableButtons, setIsDisableButtons] = useState(false)
  useEffect(() => {
    if (isCreating) {
      setIsDisableButtons(true)
      setTimeout(() => setIsDisableButtons(false), 5000)
    }
  }, [isCreating])
  useEffect(() => {
    if (saveStatus === 'error') {
      setIsDisableButtons(false)
    }
  }, [saveStatus])

  useEffect(() => {
    const filteredRoomsOptions = roomsOptions?.filter(option => option.value !== currentRoom?.id)
    const availableRooms = getAvailableRooms(schedule, filteredRoomsOptions, studioOffset, date, timeWatcher)

    setStudiosRoomsOptions(isDef(availableRooms) ? availableRooms : filteredRoomsOptions)
  }, [currentRoom?.id, date, roomsOptions, schedule, timeWatcher, studioOffset])

  return (
    <Form
      form={form}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      autoComplete="off"
      layout="vertical"
      onFinish={onFinishHandler}
      className={`exercises-form exercises-form--${theme}`}
    >
      {/* Record */}
      {/* <div className="exercises-form__radio-group">
        <Form.Item>
          <Radio.Group onChange={event => setTabs(event.target.value)} value={tabs}>
            <Radio.Button value="one-time-record">Разовая запись</Radio.Button>
            <Radio.Button value="long-record">Длинная запись</Radio.Button>
            <Radio.Button value="close-record">Закрыть запись</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </div> */}

      {/* Main Info */}
      <div className="exercises-form__section">
        <Typography.Title level={5}>О записи</Typography.Title>
        <Form.Item label="Категория записи" name="type" rules={typeValidationRules}>
          <SelectRecordType
            placeholder="Категория записи"
            loading={loading}
            showIcon={true}
            disabled={exercisesTypeIsDisabled}
            options={exercisesTypesOptions}
            onChangeSelect={onChangeExercisesTypeHandler}
            value={form.getFieldValue('type')}
          />
        </Form.Item>
        {Boolean(form.getFieldValue('type')) &&
          (selectedTypeExercise === 'PERSONAL' ? (
            <>
              <Form.Item label="Персональные услуги" name="masterService" rules={masterServiceValidationRules}>
                <SelectRecordType
                  placeholder="Персональные услуги"
                  loading={loading}
                  disabled={loading}
                  showIcon={false}
                  options={masterServicesOptions}
                  onChangeSelect={onChangeMasterServiceHandler}
                  value={form.getFieldValue('masterService')}
                />
              </Form.Item>
              <Form.Item label="Под-услуга" name="subService" rules={subServiceValidationRules}>
                <SelectRecordType
                  placeholder="Под-услуга"
                  loading={loading}
                  disabled={loading}
                  showIcon={false}
                  options={subServicesOptions}
                  onChangeSelect={onChangeSubServicesHandler}
                  value={form.getFieldValue('subService')}
                />
              </Form.Item>
            </>
          ) : (
            <Form.Item label="Групповые направления" name="direction" rules={directionValidationRules}>
              <Select
                showSearch
                placeholder="Групповые направления"
                options={directionsOptions}
                disabled={directionIsDisabled || loading}
                loading={loading}
                onChange={onChangeDirectionHandler}
                filterOption={(input, option) =>
                  option && typeof option.label === 'string'
                    ? option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    : false
                }
                dropdownClassName="customDropdown"
              />
            </Form.Item>
          ))}

        <Form.Item label="Исполнители" name="trainers">
          <SelectFilteredOptions
            value={form.getFieldValue('trainers')}
            groupLabel="Не работают"
            placeholder="Исполнители"
            loading={isCreating || isLoadingClient || loading}
            options={trainersOptions}
            showSearch
            onChange={onChangeTrainersHandler}
          />
        </Form.Item>
        {!isDef(masterServicesOptions) && (
          <Form.Item label="Вместимость" name="maxClientsCount" rules={maxClientsCountValidationRules}>
            <InputNumber
              style={{ width: '100px' }}
              placeholder="Вместимость"
              min={1}
              addonAfter={<UserOutlined />}
              disabled={loading}
              onChange={onChangeMaxClientsCountHandler}
            />
          </Form.Item>
        )}
      </div>

      {/* Time */}
      <div className="exercises-form__section">
        <Typography.Title level={5}>Время записи</Typography.Title>
        {/* <Flex gap="small" align="center">
          <Switch size="small" defaultChecked />
          <span>Длительность больше суток</span>
        </Flex> */}
        <Form.Item label="Время начала и окончания" name="time" rules={timeToValidationRules}>
          <TimeRangePicker
            times={form.getFieldValue('time')}
            onChange={onChangeTimeHandler}
            onBlur={onBlurTimeHandler}
            disabled={loading}
          />
        </Form.Item>
      </div>
      {/* Client */}
      <div className="exercises-form__section exercises-form__section--without-bg">
        <Flex gap="small" align="center">
          <Switch size="small" value={isShowGuestRecord} onChange={toggleShowGuestRecord} />
          <span className="exercises-form__section-label" onClick={toggleShowGuestRecord}>
            Запись гостя сразу
          </span>
        </Flex>
      </div>
      {isShowGuestRecord && (
        <div className="exercises-form__section">
          <Typography.Title level={5}>Выберите гостя</Typography.Title>
          <ClientsDataFields
            phoneFieldLabel={<span onClick={toggleHasFocusGuestsSearch}>Гость</span>}
            form={form}
            isLoading={loading || isLoadingClient || isCreating}
            setIsLoading={setIsLoadingClient}
            setClientData={setClientCurrentData}
          />

          <Form.Item label="Метод оплаты" name="paymentType" rules={paymentTypeValidationRules}>
            {isAvailablePaymentTypes ? (
              <Select
                placeholder="Выберите метод оплаты"
                options={paymentTypesOptions}
                onChange={onChangePaymentTypeHandler}
                disabled={loading}
              />
            ) : (
              <Tooltip title="Заполните все значение чтобы выбрать методы оплаты">
                <Select disabled placeholder="Выберите метод оплаты" />
              </Tooltip>
            )}
          </Form.Item>
        </div>
      )}
      {/* <div className="exercises-form__section exercises-form__section--without-bg">
        <Flex gap="small" align="center">
          <Switch size="small" defaultChecked />
          <span>Скрыть из виджетов онлайн-записи</span>
        </Flex>
        <Flex gap="small" align="center">
          <Switch size="small" defaultChecked />
          <span>Ограничение по параметрам клиентов</span>
        </Flex>
      </div> */}
      {(roomsOptions?.length || 0) > 1 && (
        <>
          <div className="exercises-form__section exercises-form__section--without-bg">
            <Flex gap="small" align="center">
              <Switch size="small" value={isBookSameTimeInOtherRooms} onChange={toggleBookSameTimeInOtherRooms} />
              <span className="exercises-form__section-label" onClick={toggleBookSameTimeInOtherRooms}>
                Закрыть аналогичное время под эту запись в других пространствах
              </span>
            </Flex>
          </div>
          {isBookSameTimeInOtherRooms && (
            <div className="exercises-form__section">
              <Typography.Title level={5}>Выберите пространства</Typography.Title>
              <Form.Item name="rooms">
                <SelectFilteredOptions
                  value={form.getFieldValue('rooms')}
                  groupLabel="Не доступны"
                  placeholder="Выберите пространства"
                  loading={isCreating || isLoadingClient || loading}
                  options={studiosRoomsOptions}
                  disableFilteredOptions
                  onChange={onChangeRoomsHandler}
                />
              </Form.Item>
            </div>
          )}
        </>
      )}
      <div>
        <Form.Item>
          <Flex justify="end" align="center" gap="small">
            <Button htmlType="button" onClick={onCancel} disabled={isDisableButtons}>
              Отменить
            </Button>
            <Button
              ref={submitButtonRef}
              type="primary"
              htmlType="submit"
              loading={isCreating || isLoadingClient || loading}
              disabled={isDisableButtons}
            >
              Сохранить
            </Button>
          </Flex>
        </Form.Item>
      </div>
    </Form>
  )
}
