import { createSelector } from '@reduxjs/toolkit'

import { mapStudiosToOptions } from '../../../mapping/studios.mapping'
import { mapEmployeesToOptions } from '../../../mapping/employees.mapping'
import { genFranchiseFormValues } from '../../../mapping/franchises.mapping'
import { AppState } from '../../app.store'

const getFranchiseInternal = (state: AppState) => state.franchisesEditPage.franchise
const getCustomersInternal = (state: AppState) => state.franchisesEditPage.customers
const getStudiosInternal = (state: AppState) => state.franchisesEditPage.studios

export const getFranchisesIsLoading = (state: AppState) => state.franchisesEditPage.isLoading

export const getFranchisesIsUpdating = (state: AppState) => state.franchisesEditPage.isUpdating

export const getCustomersOptions = createSelector(getCustomersInternal, customers =>
  mapEmployeesToOptions(customers?.content)
)

export const getStudiosFranchisesOptions = createSelector(getStudiosInternal, studios =>
  mapStudiosToOptions(studios?.content)
)

export const getFranchiseFormValues = createSelector(getFranchiseInternal, genFranchiseFormValues)
