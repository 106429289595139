import { useDispatch, useSelector } from 'react-redux'
import React from 'react'
import { Dayjs } from 'dayjs'

import { isDef } from '../../../types/lang.types'
import { formatDayjsToDate } from '../../../format/date.format'
import { modalActions } from '../../../store/common/modal/modal.slice'
import { AppModal } from '../../../types/modal.types'
import { ContractsApi } from '../../../api/types/contracts-api.types'
import { contractsPageActions } from '../../../store/pages/contracts-page/contracts-page.slice'
import { getCounterpartiesIsCreating } from '../../../store/pages/contracts-page/contracts-page.selectors'

function genCounterpartyDTO(values: ContractsApi.CounterpartiesDTO<Dayjs>): ContractsApi.CounterpartiesDTO {
  const { birthDate, passportIssueDate, ...restValues } = values

  return {
    ...restValues,
    birthDate: isDef(birthDate) ? formatDayjsToDate(birthDate) : null,
    passportIssueDate: isDef(passportIssueDate) ? formatDayjsToDate(passportIssueDate) : null,
  }
}

export const useContractsHeader = () => {
  const dispatch = useDispatch()
  const isLoading = useSelector(getCounterpartiesIsCreating)

  const onCreateClient = React.useCallback(
    (clientsFormValues: ContractsApi.CounterpartiesDTO<Dayjs>) => {
      const formattedData = genCounterpartyDTO(clientsFormValues)
      dispatch(contractsPageActions.createCounterparties(formattedData))
    },
    [dispatch]
  )

  const onCloseHandler = React.useCallback((): void => {
    dispatch(modalActions.closeLast())
  }, [dispatch])

  const onCreateCounterpartiesHandler = React.useCallback((): void => {
    dispatch(
      modalActions.show({
        modal: AppModal.CONTRACTS_PAGE_COUNTERPARTY_MODAL_CREATE,
        props: {
          isLoading: isLoading,
          handleOk: onCreateClient,
          handleCancel: onCloseHandler,
        },
      })
    )
  }, [dispatch, isLoading, onCloseHandler, onCreateClient])

  return {
    onCreateCounterpartiesHandler,
  }
}
