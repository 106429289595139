import { useLocation } from 'react-router-dom'
import * as React from 'react'

import { NNumber } from '../../../types/lang.types'
import { genCounterpartiesPageQueryParams } from '../contract-page.utils'

export function useCounterpartiesPageParams(): { page?: NNumber; size?: NNumber } {
  const { search } = useLocation()

  return React.useMemo(() => genCounterpartiesPageQueryParams(search), [search])
}
