import { push } from 'connected-react-router'
import { all, put, takeLatest } from 'redux-saga/effects'

import { terminalEntityActions } from '../../common/entity/terminal/terminal.slice'
import { tillEntityActions } from '../../common/entity/till/till.slice'
import { api } from '../../../api/api'
import { genFranchisesPagePath } from '../../../format/path.format'
import { genOrganizationDTO } from '../../../mapping/franchises.mapping'
import { callApi } from '../../../utils/sagas.utils'
import { franchisesEditPageActions } from './franchises-edit-page.slice'

export function* fetchPageData(action: ReturnType<typeof franchisesEditPageActions.fetchPageData>) {
  try {
    const [franchise, customers, studios]: [
      Awaited<ReturnType<typeof api.organizationsService.fetchById>>,
      Awaited<ReturnType<typeof api.employeesService.fetchAll>>,
      Awaited<ReturnType<typeof api.studiosService.fetchAll>>
    ] = yield all([
      callApi(api.organizationsService.fetchById, action.payload),
      callApi(api.employeesService.fetchAll, {
        size: 100,
      }),
      callApi(api.studiosService.fetchAll, { size: 300, orgId: action.payload }),
    ])
    yield put(terminalEntityActions.fetchAllTerminals())
    yield put(tillEntityActions.fetchAllTills())
    yield put(
      franchisesEditPageActions.fetchPageDataSuccess({
        franchise: franchise.data,
        customers: customers.data,
        studios: studios.data,
      })
    )
  } catch (e) {
    console.error(e)
    yield put(franchisesEditPageActions.fetchPageDataError(new Error()))
  }
}

export function* updateFranchise(action: ReturnType<typeof franchisesEditPageActions.updateFranchise>) {
  try {
    const { id, data } = action.payload
    const organizationDTO = genOrganizationDTO(data)

    yield callApi(api.organizationsService.update, id, organizationDTO)

    yield put(franchisesEditPageActions.updateFranchiseSuccess())
    yield put(push(genFranchisesPagePath()))
  } catch (e) {
    console.error(e)
    yield put(franchisesEditPageActions.updateFranchiseError(new Error()))
  }
}

export function* franchisesEditPageSagas() {
  yield takeLatest(franchisesEditPageActions.fetchPageData, fetchPageData)
  yield takeLatest(franchisesEditPageActions.updateFranchise, updateFranchise)
}
