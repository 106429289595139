import React from 'react'
import { Form } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { DefaultOptionType } from 'antd/lib/select'
import { ADD_PRICING_IN_SUBSERVICES } from '@constants/broadcast'
import dayjs from 'dayjs'

import {
  genMasterServicesEditPageIsLoading,
  getMasterServicesEditPageExercisesTypesOptions,
  getMasterServicesEditPagePositions,
  getMasterServicesEditPagePositionsOptions,
  getMasterServicesEditPagePricing,
  getMasterServicesEditPagePricingOptions,
  getMasterServicesEditPageSelectedPricing,
  getMasterServicesEditPageStudiosOptions,
  getMasterServicesEditPageStudiosRoomsOptions,
  getMasterServicesEditPageTrainers,
  getMasterServicesEditPageTrainersOptions,
  getMasterServicesFormValues,
} from '../../../store/pages/master-services-edit-page/master-services-edit-page.selectors'
import { isDef, isDefAndNotEmpty, NNumber, Nullable } from '../../../types/lang.types'
import { api } from '../../../api/api'
import { masterServicesEditPageActions } from '../../../store/pages/master-services-edit-page/master-services-edit-page.slice'
import { useMasterServicesEditPageParams } from '../master-services-edit-page-hooks/master-services-edit-page-params.hook'
import { mapMasterServicesgSubServicesServiceCategoriesToOptions } from '../../../mapping/master-services-subservices.mapping'
import {
  MasterServicesFormValues,
  MasterServicesPricingFormValues,
  MasterServicesSubServicesFormValues,
} from '../../../components/master-services/master-services-form/master-services-form.types'
import { formatPluralized } from '../../../format/text.format'

export function useMasterServicesEditPageForm() {
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const { id } = useMasterServicesEditPageParams()

  const isLoading = useSelector(genMasterServicesEditPageIsLoading)

  const trainers = useSelector(getMasterServicesEditPageTrainers)
  const positions = useSelector(getMasterServicesEditPagePositions)

  const savedMasterServicesFormValues = useSelector(getMasterServicesFormValues)
  const exercisesTypesOptions = useSelector(getMasterServicesEditPageExercisesTypesOptions)
  const trainersOptions = useSelector(getMasterServicesEditPageTrainersOptions)
  const studiosOptions = useSelector(getMasterServicesEditPageStudiosOptions)
  const studiosRoomsOptions = useSelector(getMasterServicesEditPageStudiosRoomsOptions)
  const generalPricingOptions = useSelector(getMasterServicesEditPagePricingOptions)
  const positionsOptions = useSelector(getMasterServicesEditPagePositionsOptions)

  const pricing = useSelector(getMasterServicesEditPagePricing)
  const selectedPricingEditPage = useSelector(getMasterServicesEditPageSelectedPricing)

  const [masterServicesFormValues, setMasterServicesFormValues] =
    React.useState<Nullable<MasterServicesFormValues>>(null)
  const [serviceCategoriesOptions, setServiceCategoriesOptions] = React.useState<DefaultOptionType[] | undefined>()
  const [timeStepMinutesOptions, setTimeStepMinutesOptions] = React.useState<DefaultOptionType[] | undefined>()
  const [pricingOptions, setPricingOptions] = React.useState<DefaultOptionType[] | undefined>()

  const [selectedPricing, setSelectedPricing] = React.useState<Nullable<MasterServicesPricingFormValues>>(null)
  const [unsavedService, setUnsavedService] = React.useState<Nullable<MasterServicesSubServicesFormValues>>(null)

  React.useEffect(() => {
    if (!savedMasterServicesFormValues) return
    if (!masterServicesFormValues) {
      setMasterServicesFormValues(savedMasterServicesFormValues)
    }
  }, [savedMasterServicesFormValues])

  const onAddServiceCategoryHandler = React.useCallback(
    (value: string): void => {
      api.serviceCategoriesService
        .create({ name: value, description: null })
        .then(res => {
          if (isDefAndNotEmpty(serviceCategoriesOptions)) {
            setServiceCategoriesOptions([{ value: res.data.id, label: res.data.name }, ...serviceCategoriesOptions])
          } else {
            setServiceCategoriesOptions([{ value: res.data.id, label: res.data.name }])
          }
        })
        .catch(error => console.error(error))
    },
    [serviceCategoriesOptions]
  )

  const onAddTimeStepHandler = React.useCallback(
    (value: NNumber): void => {
      if (value) {
        setTimeStepMinutesOptions([
          { value: value, label: formatPluralized(value, ['минута', 'минуты', 'минут']) },
          ...(isDefAndNotEmpty(timeStepMinutesOptions) ? [...timeStepMinutesOptions] : []),
        ])
      }
    },
    [timeStepMinutesOptions]
  )

  const onAddPricingHandler = React.useCallback(
    (value: MasterServicesPricingFormValues): void => {
      dispatch(masterServicesEditPageActions.createPricing(value))
    },
    [dispatch]
  )

  const onUpdatePricingHandler = React.useCallback(
    (pricingId: string, data: MasterServicesPricingFormValues): void => {
      dispatch(masterServicesEditPageActions.updatePricing({ pricingId, data }))
    },
    [dispatch]
  )

  const onSavePricingHandler = React.useCallback(
    (value: MasterServicesPricingFormValues, subServiceKey?: NNumber): void => {
      const formValues = form.getFieldsValue()
      const subService =
        isDefAndNotEmpty(formValues.subServices) && typeof subServiceKey === 'number'
          ? formValues.subServices[subServiceKey]
          : null
      const subServiceId = subService ? subService.id : null

      if (!isDef(subServiceId)) {
        setUnsavedService(subService)
      }
      if (isDef(selectedPricing) && isDef(selectedPricing.id)) {
        onUpdatePricingHandler(selectedPricing.id, value)
      } else {
        onAddPricingHandler(value)
        const bc = new BroadcastChannel(ADD_PRICING_IN_SUBSERVICES)
        bc.onmessage = event => {
          const response = JSON.parse(event.data)
          if (!response.pricingId) return
          if (!subService) return
          form.setFieldValue(
            ['subServices', subServiceKey, 'pricing'],
            [...(subService.pricing ?? []), { pricingId: response.pricingId, activeFrom: dayjs() }]
          )
        }
      }
    },
    [form, selectedPricing, onAddPricingHandler, onUpdatePricingHandler]
  )

  const onCopyPricingHandler = React.useCallback(
    async (pricingId: string, field: number, subField: number) => {
      if (isDefAndNotEmpty(pricing)) {
        const slicedPricing = pricing.find(pricing => pricing.id === pricingId)

        if (slicedPricing) {
          const fullPricing = await api.pricingService.fetchById(slicedPricing.id)
          if (!fullPricing) return

          await api.pricingService
            .create({
              name: fullPricing.data.name,
              holidayAsWeekend: fullPricing.data.holidayAsWeekend,
              basePrices: fullPricing.data.basePrices,
              priceImpacts: fullPricing.data.priceImpacts,
            })
            .then(res => {
              if (isDefAndNotEmpty(pricingOptions)) {
                setPricingOptions([{ value: res.data.id, label: res.data.name }, ...pricingOptions])
              } else {
                setPricingOptions([{ value: res.data.id, label: res.data.name }])
              }
              form.setFieldValue(['subServices', field, 'pricing', subField, 'pricingId'], res.data.id)
            })
            .catch(error => console.error(error))
        }
      }
    },
    [form, pricing, pricingOptions]
  )

  const onSelectPricingHandler = React.useCallback(
    (id: string): void => {
      dispatch(masterServicesEditPageActions.fetchPricingById(id))
    },
    [dispatch]
  )

  const onClosePricingModalHandler = React.useCallback((): void => {
    setSelectedPricing(null)
  }, [])

  const onSaveSubServiceHandler = React.useCallback(
    (key: number): void => {
      const formValues = form.getFieldsValue()
      const data = formValues.subServices ? formValues.subServices[key] : null
      const subServiceId = data ? data.id : null

      if (isDef(id) && isDef(subServiceId) && isDef(data)) {
        dispatch(
          masterServicesEditPageActions.updateSubService({
            masterServiceId: id,
            subServiceId,
            data,
          })
        )
      } else if (isDef(id) && isDef(data)) {
        const successCallback = (id: string) => {
          form.setFieldsValue({
            subServices: {
              ...formValues.subServices,
              [key]: {
                ...formValues.subServices[key],
                id,
              },
            },
          })
        }

        dispatch(
          masterServicesEditPageActions.createSubService({
            masterServiceId: id,
            data,
            successCallback,
          })
        )
      }
    },
    [dispatch, form, id]
  )

  const onRemoveSubServiceHandler = React.useCallback(
    (key: number): void => {
      const formValues = form.getFieldsValue()
      const subServiceId = formValues.subServices && formValues.subServices[key] ? formValues.subServices[key].id : null

      if (isDef(id) && isDef(subServiceId)) {
        dispatch(
          masterServicesEditPageActions.removeSubService({
            masterServiceId: id,
            subServiceId,
          })
        )
      }
    },
    [dispatch, form, id]
  )

  const onDisconnectPricingHandler = React.useCallback(
    (key: number, pricingId: string): void => {
      if (isDef(masterServicesFormValues)) {
        const formValues = form.getFieldsValue()
        const subServiceId =
          formValues.subServices && formValues.subServices[key] ? formValues.subServices[key].id : null
        const connectedPricing = masterServicesFormValues.subServices
          ?.find(subService => subService.id === subServiceId)
          ?.pricing?.find(pricing => pricing.pricingId === pricingId)

        if (isDef(subServiceId) && isDef(connectedPricing)) {
          dispatch(
            masterServicesEditPageActions.subserviceDisconnection({
              pricingId: connectedPricing.pricingId,
              subServiceId: subServiceId,
            })
          )
        }
      }
    },
    [dispatch, form, masterServicesFormValues]
  )

  const onRemovePricingHandler = React.useCallback(
    (id: string): void => {
      const successCallback = (id: string) => {
        const currentValues = form.getFieldsValue()

        const updatedSubServices = currentValues.subServices?.map((subService: any) => ({
          ...subService,
          pricing: subService.pricing?.filter((pricing: any) => pricing?.pricingId !== id) || [],
        }))

        form.setFieldsValue({
          ...currentValues,
          subServices: updatedSubServices,
        })
      }
      dispatch(masterServicesEditPageActions.removePricing({ id, successCallback }))
    },
    [form, dispatch]
  )

  const onFinishHandler = React.useCallback((): void => {
    if (isDef(id)) {
      const values = form.getFieldsValue(true)
      dispatch(masterServicesEditPageActions.updateMasterService({ id, data: values }))
    }
  }, [dispatch, form, id])

  React.useEffect(() => {
    if (isDef(masterServicesFormValues)) {
      if (isDef(unsavedService)) {
        const subServices = isDefAndNotEmpty(masterServicesFormValues.subServices)
          ? [...masterServicesFormValues.subServices, unsavedService]
          : [unsavedService]
        form.setFieldsValue({
          ...masterServicesFormValues,
          subServices,
        })
      } else {
        form.setFieldsValue(masterServicesFormValues)
      }
      setServiceCategoriesOptions(
        mapMasterServicesgSubServicesServiceCategoriesToOptions(masterServicesFormValues.subServices)
      )
      setTimeStepMinutesOptions([
        {
          label: formatPluralized(masterServicesFormValues.timeStepMinutes, ['минута', 'минуты', 'минут']),
          value: masterServicesFormValues.timeStepMinutes,
        },
      ])
    }
  }, [form, masterServicesFormValues, unsavedService])

  React.useEffect(() => {
    if (isDefAndNotEmpty(generalPricingOptions)) {
      setPricingOptions(generalPricingOptions)
    }
  }, [generalPricingOptions])

  React.useEffect(() => {
    if (isDef(selectedPricingEditPage)) {
      setSelectedPricing(selectedPricingEditPage)
    }
  }, [selectedPricingEditPage])

  return {
    form,
    trainers,
    positions,
    selectedPricing,
    isLoading,
    exercisesTypesOptions,
    trainersOptions,
    studiosOptions,
    studiosRoomsOptions,
    pricingOptions,
    positionsOptions,
    serviceCategoriesOptions,
    timeStepMinutesOptions,
    onAddServiceCategoryHandler,
    onAddTimeStepHandler,
    onSaveSubServiceHandler,
    onRemoveSubServiceHandler,
    onCopyPricingHandler,
    onDisconnectPricingHandler,
    onRemovePricingHandler,
    onSavePricingHandler,
    onSelectPricingHandler,
    onClosePricingModalHandler,
    onFinishHandler,
  }
}
