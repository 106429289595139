import { Form, Modal } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ClientsApi } from '../../../api/types/clients-api.types'
import { StudiosRoomsApi } from '../../../api/types/studios-rooms-api.types'
import { TrainersApi } from '../../../api/types/trainers-api.types'
import {
  ExercisesFormValues,
  ExercisesFormValuesDTO,
} from '../../../components/exercises/exercises-form/exercises-form.types'
import { CREATE_EXERCISE_BROADCAST } from '../../../constants/broadcast'
import { modalActions } from '../../../store/common/modal/modal.slice'
import {
  genSchedulePageListStudiosRoomsOptions,
  genSchedulePageListTableRowList,
  genStudiosRoomsInternal,
} from '../../../store/pages/schedule-page/schedule-page-list/schedule-page-list.selectors'
import {
  getSchedulePageModalCreateExercisesTypes,
  getSchedulePageModalCreateIsLoading,
  getSchedulePageModalCreateTrainers,
} from '../../../store/pages/schedule-page/schedule-page-modal-create/schedule-page-modal-create.selectors'
import { schedulePageModalCreateActions } from '../../../store/pages/schedule-page/schedule-page-modal-create/schedule-page-modal-create.slice'
import { isDef, isDefAndNotEmpty, Nullable } from '../../../types/lang.types'

export function useSchedulePageModalCreate(
  studioId: string,
  studioRoomId: string,
  date: string,
  timeFrom?: string,
  timeTo?: string,
  timetableType?: 'weekly' | 'daily'
) {
  const bc = new BroadcastChannel(CREATE_EXERCISE_BROADCAST)

  const [form] = Form.useForm<ExercisesFormValues>()

  const dispatch = useDispatch()

  const isLoading = useSelector(getSchedulePageModalCreateIsLoading)

  const exercisesTypes = useSelector(getSchedulePageModalCreateExercisesTypes)
  const trainers = useSelector(getSchedulePageModalCreateTrainers)
  const rooms = useSelector(genStudiosRoomsInternal)
  const roomsOptions = useSelector(genSchedulePageListStudiosRoomsOptions)
  const schedule = useSelector(genSchedulePageListTableRowList)

  const [room, setRoom] = useState<StudiosRoomsApi.StudioRoom | null | undefined>(null)

  const [saveStatus, setSaveStatus] = useState<'success' | 'error' | 'default'>('default')

  const onCancelHandler = useCallback((): void => {
    dispatch(modalActions.closeLast())
  }, [dispatch])

  const handleReFetchTrainers = useCallback(
    (timeFrom?: string, timeTo?: string, successCallback?: (trainers: Nullable<TrainersApi.Trainer[]>) => void) => {
      form
        .validateFields(['time'])
        .then(values =>
          dispatch(
            schedulePageModalCreateActions.fetchTrainers({
              roomId: studioRoomId,
              timeFrom,
              timeTo,
              successCallback,
            })
          )
        )
        .catch(err => {})
    },
    [dispatch, form, studioRoomId]
  )

  const handleCreateExercise = useCallback(
    (exercisesFormValuesDTO: ExercisesFormValuesDTO, clientDTO: Nullable<ClientsApi.ClientDTO>): void => {
      if (room) {
        // Create record
        dispatch(
          schedulePageModalCreateActions.createExercise({
            room,
            exercisesFormValuesDTO,
            date,
            clientDTO,
            timetableType,
          })
        )

        // Set up guest
        bc.onmessage = event => {
          const response = JSON.parse(event.data)
          setSaveStatus(response.status)
        }
      }
    },
    [bc, date, dispatch, room, timetableType]
  )

  const handleCheckTrainers = useCallback(
    (
      updatedTrainers: Nullable<TrainersApi.Trainer[]>,
      formValues: ExercisesFormValuesDTO,
      clientDTO: Nullable<ClientsApi.ClientDTO>
    ): void => {
      const { trainers } = formValues

      const unavailableTrainer = trainers?.find(
        trainer => updatedTrainers?.find(t => t.id === trainer)?.available === false
      )

      if (unavailableTrainer) {
        Modal.confirm({
          title: 'У исполнителя перерыв в выбранное время',
          content: 'Вы уверены, что хотите создать запись?',
          cancelText: 'Нет',
          okText: 'Да',
          onOk: () => handleCreateExercise(formValues, clientDTO),
        })
      } else {
        handleCreateExercise(formValues, clientDTO)
      }
    },
    [handleCreateExercise]
  )

  const onChangeTimeHandler = useCallback(
    (timeStart: string, timeEnd: string): void => {
      handleReFetchTrainers(timeStart, timeEnd)
    },
    [handleReFetchTrainers]
  )

  const onSaveHandler = useCallback(
    (exercisesGroupCreateFormValuesDTO: ExercisesFormValuesDTO, clientDTO: Nullable<ClientsApi.ClientDTO>): void => {
      const { time, trainers } = exercisesGroupCreateFormValuesDTO

      if (isDefAndNotEmpty(trainers)) {
        const timeFrom = time?.start?.slice(11, 16)
        const timeTo = time?.end?.slice(11, 16)

        handleReFetchTrainers(`${date}T${timeFrom}`, `${date}T${timeTo}`, (trainers: Nullable<TrainersApi.Trainer[]>) =>
          handleCheckTrainers(trainers, exercisesGroupCreateFormValuesDTO, clientDTO)
        )
      } else {
        handleCreateExercise(exercisesGroupCreateFormValuesDTO, clientDTO)
      }
    },
    [date, handleCheckTrainers, handleCreateExercise, handleReFetchTrainers]
  )

  useEffect(() => {
    if (isDefAndNotEmpty(rooms)) {
      setRoom(rooms.find(studioRoom => studioRoom.id === studioRoomId))
    }
  }, [rooms, studioRoomId])

  useEffect(() => {
    dispatch(
      schedulePageModalCreateActions.fetchDictionaries({
        studioId,
        roomId: studioRoomId,
        ...(isDef(timeTo) &&
          isDef(timeFrom) && {
            timeFrom: `${date}T${timeFrom}`,
            timeTo: `${date}T${timeTo}`,
          }),
      })
    )
  }, [date, dispatch, studioId, studioRoomId, timeFrom, timeTo])

  useEffect(() => {
    if (isDef(timeFrom)) {
      const formValues = form.getFieldsValue()

      form.setFieldsValue({
        ...formValues,
        time: {
          start: timeFrom,
          end: timeTo,
        },
      })
    }
  }, [form, timeFrom, timeTo])

  useEffect(() => {
    return () => {
      dispatch(schedulePageModalCreateActions.reset())
    }
  }, [dispatch])

  useEffect(() => {
    return () => {
      bc.close()
    }
  }, [])

  return {
    form,
    isLoading,
    exercisesTypes,
    trainers,
    currentRoom: room,
    roomsOptions,
    schedule,
    saveStatus,
    onChangeTimeHandler,
    onSaveHandler,
    onCancelHandler,
  }
}
