import { DefaultOptionType } from 'antd/lib/select'

import { ContractsCardProps } from '../pages/contracts-page/contracts-card/contracts-card.types'
import { formatRublesToPennies } from '../format/number.format'
import { ContractsClientTableRow } from '../components/contracts/contracts-clients-table/contracts-clients-table.types'
import { DEFAULT_EMPTY_SYMBOL, formatFullName } from '../format/text.format'
import { isDef, isDefAndNotEmpty, Nullable } from '../types/lang.types'
import { EmployeesApi } from '../api/types/employees-api.types'
import { ContractsApi } from '../api/types/contracts-api.types'

export function mapCounterpartiesToCardList(organizations: Nullable<ContractsApi.Counterparties[]>) {
  if (isDefAndNotEmpty(organizations)) {
    return organizations.map((counterparties: ContractsApi.Counterparties) => ({
      id: counterparties.id,
      bank: counterparties.bankName,
      directorName: counterparties.directorName,
      company: counterparties.companyName,
      telephone: counterparties.phoneNumber?.replace(/\D/g, ''),
      activeContracts: counterparties.activeContracts,
      email: counterparties.email,
      clientCount: counterparties.clientCount,
    }))
  }

  return null
}

export function mapManagersToDropdown(managers: Nullable<EmployeesApi.Employee[]>): DefaultOptionType[] | undefined {
  if (isDefAndNotEmpty(managers)) {
    return managers.map((manager: EmployeesApi.Employee): DefaultOptionType => {
      const { firstName, lastName, id } = manager

      const fullName = formatFullName(firstName, lastName)

      return {
        value: id,
        label: `Менеджер: ${fullName}`,
      }
    })
  }
}

export function mapClientsToClientsTable(
  clients: Nullable<ContractsApi.CounterpartiesClient[]>
): Nullable<ContractsClientTableRow[]> {
  if (isDefAndNotEmpty(clients)) {
    return clients.map(
      (client: ContractsApi.CounterpartiesClient): ContractsClientTableRow => ({
        id: client.id,
        phone: client.phone,
        name: client.firstName
          ? client.lastName
            ? `${client.firstName} ${client.lastName}`
            : client.firstName
          : DEFAULT_EMPTY_SYMBOL,
        photo: client.photo,
        lastVisit: client.lastVisit,
        category: client.category,
        turnover: client.turnover,
      })
    )
  }

  return null
}

export function getCounterpartiesDTO(data: ContractsApi.CounterpartiesDTO): Nullable<ContractsApi.CounterpartiesDTO> {
  if (isDef(data)) {
    return {
      companyName: data.companyName,
      type: data.type,
      passportIssueDate: data.passportIssueDate || null,
      passportIssuer: data.passportIssuer || null,
      passportNumber: data.passportNumber || null,
      passportSeries: data.passportSeries || null,
      birthDate: data.birthDate || null,
      displayName: data.companyName || data.directorName,
      ogrn: data.ogrn,
      inn: data.inn || null,
      kpp: data.kpp,
      snoType: data.snoType,
      address: data.address || null,
      directorName: data.directorName,
      phoneNumber: data.phoneNumber,
      email: data.email,
      bankName: data.bankName,
      bic: data.bic,
      bankAccount: data.bankAccount,
      correspondentAccount: data.correspondentAccount,
    }
  }

  return null
}

export function getContractDTO(data: ContractsApi.ContractsDTO, link: string): Nullable<ContractsApi.ContractsDTO> {
  if (isDef(data)) {
    return {
      name: data.name,
      link,
      number: data.name,
      validSince: data.validSince,
      validUntil: data.validUntil,
      type: data.type,
      paymentModel: data.paymentModel,
      maxTotalAmount: data.maxTotalAmount,
      maxPeriodAmount: data.maxPeriodAmount || null,
      disablePaymentsOnMaxAmount: data.disablePaymentsOnMaxAmount,
      disablePaymentsAfterPaymentDue: data.disablePaymentsAfterPaymentDue || true,
      counterpartyId: data.counterpartyId,
      managerId: data.managerId,
      notifyDaysBeforeExpire: data.notifyDaysBeforeExpire || 0,
    }
  }

  return null
}

export function getContractPaymentMethod(data: any): any {
  const { paymentModel } = data

  if (paymentModel === 'ONE_TIME') {
    return {
      type: paymentModel,
    }
  } else if (paymentModel === 'PERIODIC') {
    return {
      type: paymentModel,
      months: data.months,
      day: data.day,
    }
  } else if (paymentModel === 'VALUE_SPENT') {
    return {
      type: paymentModel,
      value: formatRublesToPennies(data.value),
    }
  } else if (paymentModel === 'VALUE_LEFT') {
    return {
      type: paymentModel,
      value: formatRublesToPennies(data.value),
    }
  }
}

export function getCounterpartiesTotalTurnover(
  counterparties: Nullable<ContractsApi.Counterparties[]>
): { id: string; totalTurnover: number }[] {
  return (
    counterparties?.map(counterparty => ({
      id: counterparty.id,
      totalTurnover:
        counterparty.activeContracts?.reduce((sum, contract) => sum + (contract.lifetimeTurnover || 0), 0) || 0,
    })) || []
  )
}

export function getCounterpartiesTotalBalance(
  counterparties: Nullable<ContractsApi.Counterparties[]>,
  totalTurnoverList: { id: string; totalTurnover: number }[]
): { id: string; totalBalance: number }[] {
  return (
    counterparties?.map(counterparty => {
      const totalTurnover = totalTurnoverList.find(item => item.id === counterparty.id)?.totalTurnover || 0
      const totalBalance = counterparty.activeContracts?.reduce((sum, contract) => sum + contract.maxTotalAmount, 0)
      const formattedBalance = totalBalance - totalTurnover
      return {
        id: counterparty.id,
        totalBalance: formattedBalance,
      }
    }) || []
  )
}

export function mapCounterpartiesCard(
  counterparties: Nullable<ContractsApi.Counterparties[]>,
  totalDebitAndCredit: { id: string; debit: number; credit: number }[],
  totalTurnover: { id: string; totalTurnover: number }[]
): ContractsCardProps[] {
  return (
    counterparties?.map(counterparty => {
      const turnover = totalTurnover.find(item => item.id === counterparty.id)?.totalTurnover || 0
      const debit = totalDebitAndCredit.find(item => item.id === counterparty.id)?.debit || 0
      const credit = totalDebitAndCredit.find(item => item.id === counterparty.id)?.credit || 0

      return {
        id: counterparty.id,
        type: counterparty.type,
        companyName: counterparty.companyName,
        totalTurnover: turnover,
        totalBalance: 0,
        debit: debit,
        credit: credit,
        clientCount: counterparty.clientCount,
        directorName: counterparty.directorName,
        telephone: counterparty.phoneNumber,
        email: counterparty.email,
        isActiveContracts: counterparty.activeContracts.length > 0,
      }
    }) || []
  )
}

export function getCounterpartyDebitAndCredit(counterparties: Nullable<ContractsApi.Counterparties[]>) {
  if (!counterparties) return []

  const result: { id: string; debit: number; credit: number }[] = []

  counterparties.forEach(counterparty => {
    if (isDefAndNotEmpty(counterparty.activeContracts)) {
      let debit = 0
      let credit = 0

      counterparty.activeContracts.forEach(contract => {
        if (['ACTIVE', 'AWAITS_PAYMENT'].includes(contract.status)) {
          const amount = contract.maxPeriodAmount || 0
          const turnover = contract.currentTurnover || 0
          const creditBalance = turnover
          const debitBalance = amount - turnover

          if (contract.type === 'POSTPAY') {
            credit += creditBalance
          } else if (contract.type === 'PREPAY') {
            debit += debitBalance
          }
        }
      })

      result.push({
        id: counterparty.id,
        debit,
        credit,
      })
    }
  })

  return result
}
